import { useState, useCallback, ChangeEvent, useRef } from "react"
import {
  InsertEleType,
  NavFlagType,
  ReturnIndexType,
  ReturnInputType,
  ReturnMoreButtonType,
  ReturnNavFlagType,
} from "types"
import { padTime } from "service/utils"
import dayjs from "dayjs"

export const useDepFlag = (initialData: NavFlagType[]): ReturnNavFlagType<NavFlagType[]> => {
  const [depFlag, setDepFlag] = useState(initialData)

  const onChangeDepFlag = useCallback((navIndex: number) => {
    setDepFlag((prevDepFlag) => {
      return prevDepFlag.map((eachDepFlag) => {
        if (eachDepFlag.id === navIndex) {
          return {
            ...eachDepFlag,
            flag: !eachDepFlag.flag,
          }
        }
        return eachDepFlag
      })
    })
  }, [])
  const resetDepFlag = useCallback(() => {
    setDepFlag((prevDepFlag) => {
      return prevDepFlag.map((eachDepFlag) => {
        return {
          ...eachDepFlag,
          flag: false,
        }
      })
    })
  }, [])

  return [depFlag, onChangeDepFlag, resetDepFlag, setDepFlag]
}

export const useInput = <T = any>(initialData: T, isNumber?: boolean): ReturnInputType<T> => {
  const [value, setValue] = useState(initialData)
  const handler = useCallback(
    (e: ChangeEvent<InsertEleType>) => {
      if (isNumber) setValue(e.target.value.replace(/[^0-9]/g, "") as unknown as T)
      else setValue(e.target.value as unknown as T)
    },
    [isNumber],
  )

  return [value, handler, setValue]
}

export const useTextarea = <T = any>(initialData: T, limit?: number): ReturnInputType<T> => {
  const [value, setValue] = useState(initialData)
  const handler = useCallback(
    (e: ChangeEvent<InsertEleType>) => {
      const sliceValue = e.target.value.slice(0, limit)
      setValue(sliceValue as unknown as T)
    },
    [limit],
  )

  return [value, handler, setValue]
}

export const useIndexButton = <T = any>(initialData: T): ReturnIndexType<T> => {
  const [index, setIndex] = useState(initialData)
  const handler = useCallback((tabIndex: T) => {
    setIndex(tabIndex)
  }, [])

  return [index, handler, setIndex]
}

export const useMoreButton = (initialIndex: number): ReturnMoreButtonType => {
  const [more, setMore] = useState(initialIndex)
  const handler = useCallback(() => {
    setMore((prevMore) => prevMore + 1)
  }, [])

  return [more, handler, setMore]
}

export const useTimer = () => {
  const [min, setMin] = useState("03")
  const [sec, setSec] = useState("00")
  const time = useRef(0)
  const timeRef = useRef(0)
  const startTimer = useCallback((timeout: number) => {
    time.current = timeout
    timeRef.current = window.setInterval(() => {
      setMin(padTime(Math.floor(time.current / 60)))
      setSec(padTime(time.current % 60))
      time.current -= 1
    }, 1000)
  }, [])
  const resetTimer = useCallback((callbackFunc?: () => void) => {
    clearInterval(timeRef.current)
    setMin("03")
    setSec("00")
    if (callbackFunc) callbackFunc()
  }, [])

  return [min, sec, time, timeRef, startTimer, resetTimer] as const
}

export const useNoticeModal = (startDt: string, endDt: string): any => {
  const flag = useCallback(() => {
    const sd = dayjs(startDt)
    const ed = dayjs(endDt)
    const now = dayjs()
    return now.isAfter(sd) && now.isBefore(ed)
  }, [startDt, endDt])
  return { flag }
}
