import { Navigate, useLocation } from "react-router-dom"

const subTopText = {
  join: {
    title: "서비스 가입",
    desc: "서비스 가입을 위해서<br />본인인증이 필요합니다.",
  },
  notice: {
    title: "공지사항",
    desc: "서비스 관련 공지사항<br />알려드립니다.",
  },
  news: {
    title: "스마트피싱보호 새소식",
    desc: "스마트피싱보호 새소식<br />전달드립니다.",
  },
  faq: {
    title: "자주묻는질문",
    desc: "서비스 이용에 대한<br />궁금증을 해결해 드립니다.",
  },
  inquiry: {
    title: "1:1문의",
    desc: "서비스이용에 어려운점이 있나요?<br />도와드릴게요.",
  },
  guide: {
    title: "피해대응안내",
    desc: "보이스피싱 피해대응방법<br />안내드립니다.",
  },
  share: {
    title: "피해사례공유",
    desc: "보이스피싱 피해 사례를<br />공유해주세요.",
  },
  phishing: {
    title: "피싱사례",
    desc: "피싱 예방에 유용한 정보를<br />전달해드립니다.",
  },
  cancel: {
    title: "서비스 해지",
    desc: "서비스 해지를 위해서<br />본인인증이 필요합니다.",
  },
  used: {
    title: "서비스 이용약관",
    desc: "스마트피싱보호<br />서비스 이용약관을 알려드립니다.",
  },
  personal: {
    title: "서비스 이용약관",
    desc: "스마트피싱보호<br />서비스 이용약관을 알려드립니다.",
  },
} as const

type SubTopTextType = keyof typeof subTopText

const SubpageTop = () => {
  const { pathname } = useLocation()
  const pathArr = pathname.split("/")
  pathArr.shift()
  if (!isNaN(Number(pathArr[pathArr.length - 1]))) pathArr.pop()
  const topPath = pathArr[0] === "phishing" ? pathArr[0] : pathArr.pop()

  if (subTopText[topPath as SubTopTextType] === undefined) return <Navigate replace to="/" />
  const { title, desc } = subTopText[topPath as SubTopTextType]

  return (
    <section className="subpage_top">
      <div className={`pagetop_wrap ${topPath}`}>
        <span>{title}</span>
        <strong dangerouslySetInnerHTML={{ __html: desc }} />
      </div>
    </section>
  )
}

export default SubpageTop
