export const TERMS_PERSONAL = {
  title: `개인정보 수집 및 이용 안내`,
  desc: ` 1. 수집 정보 ㈜데이터유니버스는 이용자의 원활한 고객상담 및 불만처리를 위하여 아래와 같은 정보를 수집 합니다.

	* 수집 정보 : 이름, 연락처, 이메일 주소, 고객의 문의/답변 내용에 포함된 정보

	2. 수집 및 이용 목적 수집된 정보는 고객문의 및 상담요청에 대하여 회신을 하거나, 상담을 위한 서비스 이용기록 조회를 목적으로 활용합니다.
	
	3. 보관 및 이용 기간 이용자의 정보는 문의 처리 이후 3년간 보관 후 파기됩니다.`,
}

export const TERMS_DAMAGE = {
  title: `피해사례공유 약관`,
  desc: `1. 본인이 등록한 피해사례 공유 정보(의심전화번호, 피해발생일시, 피해내용에 포함된 모든 정보)는 추가피해 확산 및 방지 목적으로 개인, 기업, 수사기관 등 제 3자에게 제공될 수 있음에 동의합니다.
	2. 허위사실 유포, 명예훼손 등 법적분쟁 발생 시 모든 책임은 피해사례 등록자에게 있으며, 피해사례의 사실확인을 위해 검증과정에 참여할 것임을 확인합니다.
	3. 의심전화번호 이용자에게 피해사례 등록 사실이 통지될 수 있으며, 사실확인을 위해 피해사례 공유 정보와 본인의 개인정보가 의심전화번호 이용자에게 제공될 수 있음에 동의합니다.
	4. 피해사례 공유자와 의심전화번호 이용자의 식별정보 및 개인정보(이름, 전화번호, 이메일 등)를 피해회복과 추가 피해예방을 위해 ㈜데이터유니버스가 취급 및 처리함에 동의합니다.
	
	
	※ 약관을 위배하는 경우, 다음과 같은 처벌을 받을 수 있습니다.
	① 사람을 비방할 목적으로 정보통신망을 통하여 공연히 사실을 적시하여 타인의 명예를 훼손하는 경우 3년 이하의 징역이나 금고 또는 2천만원 이하의 벌금에 처하고 사람을 비방할 목적으로 정보통신망을 통하여 공연히 허위의 사실을 적시하여 타인의 명예를 훼손하는 경우에는 7년 이하의 징역, 10년 이하의 자격정지 또는 5천만원 이하의 벌금에 처할 수 있습니다.
	② 개인적인 앙심 또는 괘씸함 등 사기범죄 외의 사유로 등록하시는 경우, 명예훼손에 해당될 수 있으며 이는 민·형사상의 불이익을 받을 수 있습니다.`,
}

export const TERMS_USED_SKT = {
  title: `서비스 이용약관`,
  desc: `제 1조 (목적)

	본 이용약관은 ㈜데이터유니버스(이하“회사”)에서 제공하는 '스마트피싱보호'(이하 “서비스”)서비스를 이용하는자(이하 "이용자")와 회사 간의 권리, 의무 및 책임사항 등 기본적인 사항의 규정을 목적으로 합니다.
	
	 
	
	제 2조 (용어의 정의)
	
	본 약관에서 사용되는 용어의 정의는 다음과 같습니다.
	
	1. "서비스"란 국내외 인터넷(SNS, 뉴스 등) 상에 있는 사기 관련 빅데이터와 금융기관과 제휴기관 등에 신고된 사기 데이터의 결합 분석을 통해 ‘최신 사기 유형 알림, 주요 사기 유형 알림’은 물론 ‘의심번호 통화 중 단계별 경고’, ‘가족보호 알림’ 등의 기능을 제공하여 실제 사기 피해를 예방 및 방지하는 서비스를 말합니다.
	
	2. "제휴사"란 고객이 본 서비스를 가입할 수 있도록 가입채널을 제공하는 기관 또는 사이트를 의미합니다.
	
	3. "이용자"란 본 서비스에 가입한 고객으로서, 회사가 제공하는 서비스를 이용하고 요금을 지불하는 개인을 말합니다.
	
	4. “월정액”이란 서비스의 이용요금을 서비스 해지 시까지 매월 자동으로 청구, 결제하는 방식을 말합니다.
	
	5. “앱”이란 서비스의 제공 및 이용을 위해 단말기에 설치되는 '스마트피싱보호' 스마트폰 전용 애플리케이션을 말합니다.
	
	6. 본 조 및 본 약관의 다른 조항에서 정의되지 않은 용어는 관계 법령 및 서비스 안내에서 정한 바에 따릅니다.
	
	 
	
	제 3조 (약관의 효력 및 변경)
	
	1. 회사는 이 약관을 회사가 운영하는 사이트나 서비스 신청 화면에 게시하여 이용자에게 공시함으로써 약관의 효력이 발생합니다.
	
	2. 회사는 필요하다고 인정되는 경우 이 약관을 변경할 수 있으며, 회사가 약관을 변경할 경우에는 적용일자 및 변경사유를 명시하여 회사가 운영하는 사이트에서 적용일자 7일 전에 공지합니다.
	
	3. 회사가 제2항에 따라 변경 약관을 공지하였음에도 이용자가 약관 변경 적용일까지 변경 약관에 대한 거부의사를 표시하지 않으면 약관의 변경에 동의한 것으로 간주합니다. 이용자는 변경된 약관 사항에 동의하지 않으면 서비스 이용을 중단하고 이용 계약을 해지할 수 있습니다.
	
	4. 가입을 신청하려는 자(이하 “가입신청자”)가 “약관에 동의”의 체크박스를 체크하였을 경우 본 약관의 내용을 모두 인지하였으며 서비스와 관련하여 회사에서 제공하거나 제공할 모든 서비스에 동의한 것으로 봅니다.
	
	5. 가입신청자 및 이용자가 이 약관의 내용(약관 변경 시 변경된 내용 포함)을 알지 못하여 발생하는 손해 및 피해에 대해서는 회사는 일체 책임을 지지 않습니다.
	
	 
	
	제 4조 (이용 신청 및 이용 계약의 성립)
	
	1. 이용계약은 가입신청자가 약관의 내용에 동의한 후 회원가입을 신청 하고 회사가 이를 승낙함으로써 체결됩니다.
	
	2. 피싱·해킹보상보험은 본 서비스 가입 익일 0시부터 서비스 해지일시까지 적용됩니다.
	
	 
	
	제 5조 (서비스의 내용)
	
	스마트피싱보호란 국내외 인터넷(SNS, 뉴스 등) 상에 있는 사기 관련 빅데이터와 금융기관과 제휴기관 등에 신고된 사기 데이터의 결합 분석을 통해 ‘최신 사기 유형 알림, 주요 사기 유형 알림’은 물론 ‘의심번호 통화 중 단계별 경고’, ‘가족보호 알림’ 등의 기능을 제공하여 실제 사기 피해를 예방 및 방지하는 서비스를 말합니다.
	
	 
	
	제 6조 (서비스 이용 요금 및 제공 시간)
	
	1. 이용요금 : 월정액 1,650원(부가세 포함)
	
	2. 피싱해킹보상보험 : 피싱·해킹금융사기 보상을 위한 기업비용보상보험은 이용요금에 포함되지 않은 ㈜데이터유니버스가 “서비스” 이용 고객에게 무상으로 제공하는 보험이며, 정상적으로 서비스를 제공받는 경우(휴대폰 이용 정지/해지 등 제외)에만 적용됩니다.
	
	3. 결제방식 : 매월 1항의 요금이 이동통신사의 요금고지서에 표시되어 휴대폰 요금에 합산되어 자동 결제됩니다. 서비스 이용요금의 결제방법 및 결제일은 가입자가 사용하는 휴대전화 이동통신사의 정책에 따릅니다.
	
	4. 회사가 서비스를 제공하는 기간은 서비스 이용신청을 회사가 승낙한 날로부터 서비스 이용계약의 해지 또는 서비스 종료 때까지입니다.
	
	5. 서비스는 연중무휴 1일 24시간 제공을 원칙으로 합니다.
	
	 
	
	제 7조 (이용신청에 대한 승낙의 유보 및 서비스 이용의 제한)
	
	1. 제 3조에 따라 가입신청자가 서비스 이용을 신청하면 회사는 원칙적으로 이를 승낙합니다. 단, 다음 각 호에 해당하는 신청에 대해서는 그 사유가 해소될 때까지 승낙을 유보하거나 거부할 수 있습니다.
	
	① 기술상 서비스를 제공할 수 없는 경우
	
	② 가입신청자가 서비스 이용요금의 납부를 지체하여 이동통신사 정책에 따른 서비스 이용 정지 대상이 된 경우
	
	③ 가입자의 단말기가 문자 수신(SMS/MMS), 앱 PUSH 수신에 문제가 있는 경우 또는 기타 사유로 문자 전송(SMS/MMS), 앱 PUSH 전송을 받을 수 없는 경우
	
	④ 사용하는 단말기의 운영체제(OS), 기기정보 등을 루팅, 탈옥 등을 통해 임의로 변경한 경우
	
	⑤ 단말기 특성 또는 OS 버전의 문제로 인해 정상적인 서비스 제공이 불가능한 경우
	
	⑥ 가입신청자가 선불 이동전화 요금제 등 서비스를 이용할 수 없는 일부 특수 이동전화 요금제에 가입되어 있거나, 이용자가 해당 요금제로 변경하는 경우
	
	2. 회사는 정기점검 및 기타 기술상의 이유, 기타 운영상의 사유와 목적에 따라 회사가 정한 기간에 일시 중지될 수 있으며, 제휴사의 기술상, 운영상의 사유와 목적에 따라 사전 공지 없이 일시 중지될 수 있습니다
	
	3. 회사는 전시•사변•천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우와 전기통신사업법에 의한 기간통신사업자가 전기통신서비스를 중지하는 등 기타 부득이한 사유가 있는 경우에는 서비스의 전부 또는 일부를 제한하거나 정지할 수 있습니다.
	
	4. 회원이 본 약관에 동의함은 서비스와 관련하여 회사에서 제공하거나 제공할 모든 서비스에 동의하는 것으로 봅니다.
	
	 
	
	제 8조 (서비스 청약 철회)
	
	1. 회사와 유료서비스 이용에 관한 계약을 체결한 회원은 계약 내용에 관한 서면을 받은 날(그 서면을 받은 때보다 서비스 이용 가능일이 늦은 경우에는 서비스 이용 가능일)부터 7일 이내에 별도의 수수료‧위약금 등의 부담 없이 청약철회를 할 수 있습니다.
	
	2. 회원은 다음 각 호에 해당하는 경우에는 회사의 의사에 반하여 제1항에 따른 청약철회를 할 수 없습니다. 단, 제2호 내지 제4호의 경우, 사전에 청약 철회 제한 사실을 회원이 쉽게 알 수 있는 곳에 명기하거나 서비스 관련 정보를 제공하는 등의 조치를 취하지 않은 경우는 제외됩니다.
	
	① 회원에게 책임이 있는 사유로 재화, 콘텐츠 등(이하 “서비스 등”)이 멸실되거나 훼손된 경우
	
	② 다음 각 목을 포함하여 회원이 서비스 등을 사용 또는 일부 소비한 경우
	
	가. 가입 즉시 사용되거나 적용되는 경우
	
	나. 부가적인 혜택이 제공되는 경우 또는 추가 혜택이 사용되거나 일부가 사용된 경우
	
	다. 용역 또는 디지털콘텐츠의 제공이 개시된 경우
	
	③ 서비스 등의 제공이 개시된 경우.
	
	④ 기타 「전자상거래 등에서의 소비자보호에 관한 법률」 기타 관련 법령에 따라 청약 철회 제한 사유에 해당하는 경우
	
	3. 제1항 및 제2항에도 불구하고 서비스 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 서비스 등을 제공받은 날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.
	
	4. 회원은 구두 또는 서면(전자문서 포함)으로 본 조에 따른 청약철회를 할 수 있습니다.
	
	 
	
	제 9조 (청약 철회 및 해지 등의 효과)
	
	1. 이용자는 회사가 정한 절차에 따라 서비스를 해지할 수 있으며 이 때 즉시 해지됩니다.
	
	2. 서비스가 해지되는 경우, 해지일자를 기준으로 해당월의 서비스 이용료는 일할 계산되어 익월 이동전화 청구서에 청구됩니다.
	
	3. 서비스 앱 삭제가 서비스 해지를 뜻하는 것은 아닙니다. 해지는 별도 절차이며 인터넷사이트를 이용한 해지 또는 전화를 이용한 해지를 하셔야 합니다.
	
	4. 인터넷을 이용한 해지 : www.antiscam.co.kr의 서비스 해지 메뉴를 통해 해지하실 수 있습니다.
	
	5. 앱을 이용한 해지 : 스마트피싱보호 앱 내 해지 메뉴를 통해 해지하실 수 있습니다.
	
	6. 전화를 이용한 해지 : 고객센터(1811-7531) 또는 이동통신사 고객센터 (휴대폰 +114)에서 해지하실 수 있습니다.
	
	7. 회사는 이용자가 제8조 제1항 또는 제3항에 따라 청약철회 등을 한 경우, 청약철회 등을 한 날부터 3영업일 이내에 이미 지급 받은 서비스 등의 대금을 환급하여야 합니다. 이 경우 회사가 이용자에게 서비스 등의 대금 환급을 지연한 때에는 그 지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률 시행령」 제21조의3에서 정하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.
	
	8. 회사는 제1항의 대금을 환급함에 있어서 회원이 신용카드 또는 전자화폐 등의 결제수단으로 서비스 등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 청구를 정지 또는 취소하도록 요청합니다. 단, 회사의 내부 환불 지급 정책에 따라 이용자가 대금을 지급한 결제수단 이외의 방법으로 환불할 수 있으며 이 경우 이용자에게 고지합니다.
	
	 
	
	제 10조 (정보의 제공 및 광고의 게재)
	
	1. 회사는 서비스를 운영할 때 결제 안내, 정책 변경, 장애 등 필수 주요 내용은 홈페이지에 게재하거나 이메일(E-mail), 문자메시지(SMS/MMS), Push message 등의 방법으로 이용자에게 제공할 수 있습니다.
	
	2. 회사는 서비스를 운영할 때 회사 또는 제휴사의 서비스 관련 각종 광고를 이용자의 동의를 얻어 이메일(e-mail), 문자메시지(SMS/MMS), Push message 등의 방법으로 이용자에게 제공할 수 있습니다.
	
	 
	
	제 11조 (데이터 수집 및 사용)
	
	1. 회사는 업무와 관련하여 이용자의 사전 동의 없이 특정 개인을 알아볼 수 없는 형태로 가공된, 이용자 전체 또는 일부의 정보에 관한 통계자료를 작성하여 사용할 수 있고, 이를 위하여 이용자의 휴대폰 단말에 쿠키(Cookie : 인터넷 웹사이트의 방문기록 정보)를 전송할 수 있습니다. 이때 회원은 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여 경고하도록 사용하는 휴대폰 단말의 브라우저 설정을 변경할 수 있습니다. 쿠키의 설정 변경으로 말미암아 서비스 이용이 변경되는 것은 회원의 책임입니다.
	
	2. 회사는 앱에 포함된 광고의 통계 수집을 위해 광고 노출수와 광고 클릭수 등을 수집합니다.
	
	3. 수집한 데이터는 통계자료로만 활용됩니다.
	
	 
	
	제 12조 (회사의 의무)
	
	1. 회사는 본 약관에 정한 바에 따라 지속적, 안정적으로 서비스를 제공할 의무가 있습니다. 단, 전쟁 지진, 재난 등 천재 지변이나 불가피한 시스템의 고장, 통신서비스 회사의 통신 두절 등 불가항력의 사유가 발생한 경우에는 본 서비스를 중단할 수 있습니다.
	
	2. 회사는 서비스 제공과 관련하여 취득한 이용자 정보를 본인의 사전 승낙 없이 제 3자에게 누설 또는 배포하지 않습니다. 다만, 관계법령에 의한 수사상의 목적으로 관계기관으로부터 요구 받은 경우나 국가기관의 요청이 있는 경우에는 그러하지 않습니다.
	
	3. 회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 이용자와의 계약 관련 절차 및 내용 등에 있어 편의를 제공하도록 노력합니다.
	
	4. 회사는 이용자에게 안정적인 서비스 제공을 위하여 지속적으로 관련 시스템이나 절차, 기능 등의 예방 점검, 유지 보수 등을 이행하며, 회사 내부의 원인에 의해 서비스 장애가 발생하는 경우에는 부득이한 사유가 없는 한 곧바로 이를 수리 및 복구합니다.
	
	5. 회사는 서비스와 관련한 이용자의 불만사항을 접수하면 신속히 처리해야 할 의무가 있습니다.
	
	 
	
	제 13조 (이용자의 의무)
	
	1. 이용자는 본인이 설정한 '서비스' 설정에 책임을 지며, 이용자 부주의로 인해 발생하는 모든 결과에 대한 책임은 이용자에게 있습니다.
	
	2. 이용자는 관계 법령, 본 약관의 규정, 이용안내 및 서비스 상에 공지한 주의사항, 회사가 통지하는 사항 등을 따라야 하며, 기타 회사의 업무에 방해되는 행위를 해서는 안됩니다.



	
	제 14조 (이용자의 개인정보 보호)
	
	1. 회사는 관련 법령이 정하는 바에 따라 이용자 등록정보를 포함한 이용자의 개인정보를 보호하기 위하여 노력합니다.
	
	2. 이용자의 개인정보 보호와 관련한 사항은 관계 법령과 회사가 정하는 개인정보처리방침을 따릅니다. 서비스 개인정보처리방침은 회사와 이용자 간 이용계약의 일부를 구성합니다.
	
	 
	
	제 15조 (피싱해킹금융사기보상-기업비용보상보험)
	
	1. 회사가 보험계약을 체결하며, 보험 계약과 관련된 모든 권리 의무는 회사가 행사합니다. 보험료는 전액 회사가 부담하며, 보험금 지급 사유 발생 시 서비스 이용자 1인 1사고당 보험한도액은 300만원입니다. 보험금 지급 시 자기부담금(60만원)이 발생하며, 증권상 최대보상한도액 초과 시 보험금이 지급되지 않을 수 있습니다.
	
	2. 피싱해킹금융사기 보상을 위한 기업비용보상보험은 이용요금과 무관한 보험이며, 보험한도액 및 자기부담금은 사고율에 따라 증감이 될 수 있고 보험계약이 철회될 수 있습니다.
	
	 
	
	제 16조 (손해배상)
	
	1. 회사와 이용자는 일방 당사자의 귀책사유로 인하여 상대방에게 발생한 손해에 대하여 배상해야 합니다.
	
	2. 회사는 본 서비스 이용자의 고의 또는 과실로 발생한 손해에 대해서 어떠한 책임도 지지 않습니다.
	
	 
	
	제 17조 (면책사항)
	
	1. 회사는 천재지변 또는 이에 준하는 불가항력으로 말미암아 서비스를 제공할 수 없을 떄에는 서비스 제공에 대한 책임이 면제됩니다.
	
	2. 회사는 전기통신서비스 특성상 불가피한 사유로 서비스를 제공할 수 없거나 관련 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 일시적으로 서비스 제공을 중단하는 것에 책임을 지지 않습니다.
	
	3. 회사는 이용자의 고의, 과실 또는 서비스 이용방법에 대한 미숙지 등의 귀책사유로 서비스 이용의 장애, 회원정보나 자료소실 또는 손해가 발생한 것에 책임을 지지 않습니다.
	
	4. 회사는 이용자가 자료의 저장, 공유를 함으로써 발생하는 법적인 문제에 책임을 지지 않습니다.
	
	5. 회사는 이용자 상호 간 또는 이용자와 제 3자 상호간에 서비스를 매개로 발생한 분쟁에 개입할 의무가 없으며 이 때문에 발생한 손해를 배상할 책임도 없습니다.
	
	6. 이용자가 자신의 개인정보를 타인에게 유출 또는 제공하거나 무단으로 사용 당하는 등 그 관리를 소홀히 했을 때 발생하는 피해에 대해 회사는 일절 책임을 지지 않습니다.
	
	7. 회사는 이용자의 휴대폰 단말 오류, 제 3자 제공 프로그램의 종료 및 오류 또는 네트워크 환경상의 장애 및 오류로 인한 파일 손상 또는 업로드 및 다운로드 도중 파일이 잘못 저장된 것에 책임을 지지 않습니다.
	
	8. 이용자의 상태 변경, 이용 계약 해지 또는 요금 미납으로 인해 서비스 이용이 정지되어 이용자에게 손해가 발생하였을 때 회사는 이에 책임을 지지 않습니다.
	
	9. 회사가 공식적으로 제공한 경로가 아닌, 비공식적 경로를 통해 자사의 서비스 앱을 다운로드/설치한 이용자에게 발생한 손해에 대하여 회사는 책임을 지지 않습니다.
	
	10. 공식적 경로를 통하지 않고 설치하거나, 설치된 타 앱에 의한 손해는 회사에 책임을 지지 않습니다.
	
	 
	
	제 18조 (약관 외 준칙)
	
	이 약관에 명시되지 아니한 사항에 대해서는 관계 법령과 회사가 정한 본 서비스의 세부이용지침 등의 규정을 따릅니다.
	
	 
	
	제 19조 (관할법원)
	
	1. 서비스 이용과 관련하여 회사와 이용자 사이에 분쟁이 발생하면 회사와 이용자는 분쟁의 해결을 위해 성실히 협의합니다.
	
	2. 제1항의 협의에서도 분쟁이 해결되지 않으면 양 당사자는 민사소송법상의 관할법원에 소를 제기할 수 있습니다.
	
	 
	
	 
	
	부칙
	
	(시행일) 본 약관은 2021년 11월 28일부터 시행됩니다.
	
	`,
}
export const TERMS_USED_KT_PAY = {
  title: `서비스 이용약관`,
  desc: `제 1조 (목적)

	본 이용약관은 ㈜데이터유니버스(이하“회사”)에서 제공하는 '스마트피싱보호'(이하 “서비스”)서비스를 이용하는자(이하 "이용자")와 회사 간의 권리, 의무 및 책임사항 등 기본적인 사항의 규정을 목적으로 합니다.
	
	 
	
	제 2조 (용어의 정의)
	
	본 약관에서 사용되는 용어의 정의는 다음과 같습니다.
	
	1. "서비스"란 국내외 인터넷(SNS, 뉴스 등) 상에 있는 사기 관련 빅데이터와 금융기관과 제휴기관 등에 신고된 사기 데이터의 결합 분석을 통해 ‘최신 사기 유형 알림, 주요 사기 유형 알림’은 물론 ‘의심번호 통화 중 단계별 경고’, ‘가족보호 알림’ 등의 기능을 제공하여 실제 사기 피해를 예방 및 방지하는 서비스를 말합니다.
	
	2. "제휴사"란 고객이 본 서비스를 가입할 수 있도록 가입채널을 제공하는 기관 또는 사이트를 의미합니다.
	
	3. "이용자"란 본 서비스에 가입한 고객으로서, 회사가 제공하는 서비스를 이용하고 요금을 지불하는 개인을 말합니다.
	
	4. “월정액”이란 서비스의 이용요금을 서비스 해지 시까지 매월 자동으로 청구, 결제하는 방식을 말합니다.
	
	5. “앱”이란 서비스의 제공 및 이용을 위해 단말기에 설치되는 '스마트피싱보호' 스마트폰 전용 애플리케이션을 말합니다.
	
	6. 본 조 및 본 약관의 다른 조항에서 정의되지 않은 용어는 관계 법령 및 서비스 안내에서 정한 바에 따릅니다.
	
	 
	
	제 3조 (약관의 효력 및 변경)
	
	1. 회사는 이 약관을 회사가 운영하는 사이트나 서비스 신청 화면에 게시하여 이용자에게 공시함으로써 약관의 효력이 발생합니다.
	
	2. 회사는 필요하다고 인정되는 경우 이 약관을 변경할 수 있으며, 회사가 약관을 변경할 경우에는 적용일자 및 변경사유를 명시하여 회사가 운영하는 사이트에서 적용일자 7일 전에 공지합니다.
	
	3. 회사가 제2항에 따라 변경 약관을 공지하였음에도 이용자가 약관 변경 적용일까지 변경 약관에 대한 거부의사를 표시하지 않으면 약관의 변경에 동의한 것으로 간주합니다. 이용자는 변경된 약관 사항에 동의하지 않으면 서비스 이용을 중단하고 이용 계약을 해지할 수 있습니다.
	
	4. 가입을 신청하려는 자(이하 “가입신청자”)가 “약관에 동의”의 체크박스를 체크하였을 경우 본 약관의 내용을 모두 인지하였으며 서비스와 관련하여 회사에서 제공하거나 제공할 모든 서비스에 동의한 것으로 봅니다.
	
	5. 가입신청자 및 이용자가 이 약관의 내용(약관 변경 시 변경된 내용 포함)을 알지 못하여 발생하는 손해 및 피해에 대해서는 회사는 일체 책임을 지지 않습니다.
	
	 
	
	제 4조 (이용 신청 및 이용 계약의 성립)
	
	1. 이용계약은 가입신청자가 약관의 내용에 동의한 후 회원가입을 신청 하고 회사가 이를 승낙함으로써 체결됩니다.
	
	 
	
	제 5조 (서비스 이용 요금 및 제공 시간)
	
	1. 이용요금 : 월정액 1,650원(부가세 포함)
	
	2. 결제방식 : 매월 1항의 요금이 이동통신사의 요금고지서에 표시되어 휴대폰 요금에 합산되어 자동 결제됩니다. 서비스 이용요금의 결제방법 및 결제일은 가입자가 사용하는 휴대전화 이동통신사의 정책에 따릅니다.
	
	3. 회사가 서비스를 제공하는 기간은 서비스 이용신청을 회사가 승낙한 날로부터 서비스 이용계약의 해지 또는 서비스 종료 때까지입니다.
	
	4. 서비스는 연중무휴 1일 24시간 제공을 원칙으로 합니다.
	
	 
	
	제 6조 (이용신청에 대한 승낙의 유보 및 서비스 이용의 제한)
	
	1. 제 3조에 따라 가입신청자가 서비스 이용을 신청하면 회사는 원칙적으로 이를 승낙합니다. 단, 다음 각 호에 해당하는 신청에 대해서는 그 사유가 해소될 때까지 승낙을 유보하거나 거부할 수 있습니다.
	
	① 기술상 서비스를 제공할 수 없는 경우
	
	② 가입신청자가 서비스 이용요금의 납부를 지체하여 이동통신사 정책에 따른 서비스 이용 정지 대상이 된 경우
	
	③ 가입자의 단말기가 문자 수신(SMS/MMS), 앱 PUSH 수신에 문제가 있는 경우 또는 기타 사유로 문자 전송(SMS/MMS), 앱 PUSH 전송을 받을 수 없는 경우
	
	④ 사용하는 단말기의 운영체제(OS), 기기정보 등을 루팅, 탈옥 등을 통해 임의로 변경한 경우
	
	⑤ 단말기 특성 또는 OS 버전의 문제로 인해 정상적인 서비스 제공이 불가능한 경우
	
	⑥ 가입신청자가 선불 이동전화 요금제 등 서비스를 이용할 수 없는 일부 특수 이동전화 요금제에 가입되어 있거나, 이용자가 해당 요금제로 변경하는 경우
	
	2. 회사는 정기점검 및 기타 기술상의 이유, 기타 운영상의 사유와 목적에 따라 회사가 정한 기간에 일시 중지될 수 있으며, 제휴사의 기술상, 운영상의 사유와 목적에 따라 사전 공지 없이 일시 중지될 수 있습니다
	
	3. 회사는 전시•사변•천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우와 전기통신사업법에 의한 기간통신사업자가 전기통신서비스를 중지하는 등 기타 부득이한 사유가 있는 경우에는 서비스의 전부 또는 일부를 제한하거나 정지할 수 있습니다.
	
	4. 회원이 본 약관에 동의함은 서비스와 관련하여 회사에서 제공하거나 제공할 모든 서비스에 동의하는 것으로 봅니다.
	
	 
	
	제 7조 (서비스 청약 철회)
	
	1. 본 서비스는 디지털컨텐츠로 제공되는 상품이며 게시되는 즉시 상품 이용이 되므로 청약철회가 불가능합니다. 다만 관련 법령이 정한 바에 따라 가입자의 권리 행사가 가능하도록 합니다.
	
	 
	
	제 8조 (청약 철회 등의 효과)
	
	1. 이용자는 회사가 정한 절차에 따라 서비스를 해지할 수 있으며 이 때 즉시 해지됩니다.
	
	2. 서비스가 해지되는 경우, 해지일자를 기준으로 해당월의 서비스 이용료는 일할 계산되어 익월 이동전화 청구서에 청구됩니다.
	
	3. 서비스 앱 삭제가 서비스 해지를 뜻하는 것은 아닙니다. 해지는 별도 절차이며 인터넷사이트를 이용한 해지 또는 전화를 이용한 해지를 하셔야 합니다.
	
	4. 인터넷을 이용한 해지 : www.antiscam.co.kr의 서비스 해지 메뉴를 통해 해지하실 수 있습니다.
	
	5. 앱을 이용한 해지 : 스마트피싱보호 앱 내 해지 메뉴를 통해 해지하실 수 있습니다.
	
	6. 전화를 이용한 해지 : 고객센터(1811-7531) 또는 이동통신사 고객센터 (휴대폰 +114)에서 해지하실 수 있습니다.
	
	7. 서비스 청약 철회로 인한 환불은 당사 별도 정책에 따릅니다.
	
	 
	
	제 9조 (정보의 제공 및 광고의 게재)
	
	1. 회사는 서비스를 운영할 때 결제 안내, 정책 변경, 장애 등 필수 주요 내용은 홈페이지에 게재하거나 이메일(E-mail), 문자메시지(SMS/MMS), Push message 등의 방법으로 이용자에게 제공할 수 있습니다.
	
	2. 회사는 서비스를 운영할 때 회사 또는 제휴사의 서비스 관련 각종 광고를 이용자의 동의를 얻어 이메일(e-mail), 문자메시지(SMS/MMS), Push message 등의 방법으로 이용자에게 제공할 수 있습니다.
	
	 
	
	제 10조 (데이터 수집 및 사용)
	
	1. 회사는 업무와 관련하여 이용자의 사전 동의 없이 특정 개인을 알아볼 수 없는 형태로 가공된, 이용자 전체 또는 일부의 정보에 관한 통계자료를 작성하여 사용할 수 있고, 이를 위하여 이용자의 휴대폰 단말에 쿠키(Cookie : 인터넷 웹사이트의 방문기록 정보)를 전송할 수 있습니다. 이때 회원은 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여 경고하도록 사용하는 휴대폰 단말의 브라우저 설정을 변경할 수 있습니다. 쿠키의 설정 변경으로 말미암아 서비스 이용이 변경되는 것은 회원의 책임입니다.
	
	2. 회사는 앱에 포함된 광고의 통계 수집을 위해 광고 노출수와 광고 클릭수 등을 수집합니다.
	
	3. 수집한 데이터는 통계자료로만 활용됩니다.
	
	 
	
	제 11조 (회사의 의무)
	
	1. 회사는 본 약관에 정한 바에 따라 지속적, 안정적으로 서비스를 제공할 의무가 있습니다. 단, 전쟁 지진, 재난 등 천재 지변이나 불가피한 시스템의 고장, 통신서비스 회사의 통신 두절 등 불가항력의 사유가 발생한 경우에는 본 서비스를 중단할 수 있습니다.
	
	2. 회사는 서비스 제공과 관련하여 취득한 이용자 정보를 본인의 사전 승낙 없이 제 3자에게 누설 또는 배포하지 않습니다. 다만, 관계법령에 의한 수사상의 목적으로 관계기관으로부터 요구 받은 경우나 국가기관의 요청이 있는 경우에는 그러하지 않습니다.
	
	3. 회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 이용자와의 계약 관련 절차 및 내용 등에 있어 편의를 제공하도록 노력합니다.
	
	4. 회사는 이용자에게 안정적인 서비스 제공을 위하여 지속적으로 관련 시스템이나 절차, 기능 등의 예방 점검, 유지 보수 등을 이행하며, 회사 내부의 원인에 의해 서비스 장애가 발생하는 경우에는 부득이한 사유가 없는 한 곧바로 이를 수리 및 복구합니다.
	
	5. 회사는 서비스와 관련한 이용자의 불만사항을 접수하면 신속히 처리해야 할 의무가 있습니다.
	
	 
	
	제 12조 (이용자의 의무)
	
	1. 이용자는 본인이 설정한 '서비스' 설정에 책임을 지며, 이용자 부주의로 인해 발생하는 모든 결과에 대한 책임은 이용자에게 있습니다.
	
	2. 이용자는 관계 법령, 본 약관의 규정, 이용안내 및 서비스 상에 공지한 주의사항, 회사가 통지하는 사항 등을 따라야 하며, 기타 회사의 업무에 방해되는 행위를 해서는 안됩니다.
	
	 
	
	제 13조 (이용자의 개인정보 보호)
	
	1. 회사는 관련 법령이 정하는 바에 따라 이용자 등록정보를 포함한 이용자의 개인정보를 보호하기 위하여 노력합니다.
	
	2. 이용자의 개인정보 보호와 관련한 사항은 관계 법령과 회사가 정하는 개인정보처리방침을 따릅니다. 서비스 개인정보처리방침은 회사와 이용자 간 이용계약의 일부를 구성합니다.
	
	 
	
	제 14조 (손해배상)
	
	1. 회사와 이용자는 일방 당사자의 귀책사유로 인하여 상대방에게 발생한 손해에 대하여 배상해야 합니다.
	
	2. 회사는 본 서비스 이용자의 고의 또는 과실로 발생한 손해에 대해서 어떠한 책임도 지지 않습니다.
	
	 
	
	제 15조 (면책사항)
	
	1. 회사는 천재지변 또는 이에 준하는 불가항력으로 말미암아 서비스를 제공할 수 없을 떄에는 서비스 제공에 대한 책임이 면제됩니다.
	
	2. 회사는 전기통신서비스 특성상 불가피한 사유로 서비스를 제공할 수 없거나 관련 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 일시적으로 서비스 제공을 중단하는 것에 책임을 지지 않습니다.
	
	3. 회사는 이용자의 고의, 과실 또는 서비스 이용방법에 대한 미숙지 등의 귀책사유로 서비스 이용의 장애, 회원정보나 자료소실 또는 손해가 발생한 것에 책임을 지지 않습니다.
	
	4. 회사는 이용자가 자료의 저장, 공유를 함으로써 발생하는 법적인 문제에 책임을 지지 않습니다.
	
	5. 회사는 이용자 상호 간 또는 이용자와 제 3자 상호간에 서비스를 매개로 발생한 분쟁에 개입할 의무가 없으며 이 때문에 발생한 손해를 배상할 책임도 없습니다.
	
	6. 이용자가 자신의 개인정보를 타인에게 유출 또는 제공하거나 무단으로 사용 당하는 등 그 관리를 소홀히 했을 때 발생하는 피해에 대해 회사는 일절 책임을 지지 않습니다.
	
	7. 회사는 이용자의 휴대폰 단말 오류, 제 3자 제공 프로그램의 종료 및 오류 또는 네트워크 환경상의 장애 및 오류로 인한 파일 손상 또는 업로드 및 다운로드 도중 파일이 잘못 저장된 것에 책임을 지지 않습니다.
	
	8. 이용자의 상태 변경, 이용 계약 해지 또는 요금 미납으로 인해 서비스 이용이 정지되어 이용자에게 손해가 발생하였을 때 회사는 이에 책임을 지지 않습니다.
	
	9. 회사가 공식적으로 제공한 경로가 아닌, 비공식적 경로를 통해 자사의 서비스 앱을 다운로드/설치한 이용자에게 발생한 손해에 대하여 회사는 책임을 지지 않습니다.
	
	10. 공식적 경로를 통하지 않고 설치하거나, 설치된 타 앱에 의한 손해는 회사에 책임을 지지 않습니다.
	
	 
	
	제 16조 (약관 외 준칙)
	
	이 약관에 명시되지 아니한 사항에 대해서는 관계 법령과 회사가 정한 본 서비스의 세부이용지침 등의 규정을 따릅니다.
	
	 
	
	제 17조 (관할법원)
	
	1. 서비스 이용과 관련하여 회사와 이용자 사이에 분쟁이 발생하면 회사와 이용자는 분쟁의 해결을 위해 성실히 협의합니다.
	
	2. 제1항의 협의에서도 분쟁이 해결되지 않으면 양 당사자는 민사소송법상의 관할법원에 소를 제기할 수 있습니다.
	
	 
	
	 
	
	부칙
	
	(시행일) 본 약관은 2020년 4월 28일부터 시행됩니다.`,
}
export const TERMS_USED_KT_FREE = {
  title: `서비스이용약관`,
  desc: `제 1조 (목적)

	본 이용약관은 ㈜데이터유니버스(이하“회사”)에서 제공하는 '스마트피싱보호'(이하 “서비스”)서비스를 만 65세 이상 무료로 이용하는자(이하 "이용자")와 회사 간의 권리, 의무 및 책임사항 등 기본적인 사항의 규정을 목적으로 합니다.
	
	 
	
	제 2조 (용어의 정의)
	
	본 약관에서 사용되는 용어의 정의는 다음과 같습니다.
	
	1. "서비스"란 국내외 인터넷(SNS, 뉴스 등) 상에 있는 사기 관련 빅데이터와 금융기관과 제휴기관 등에 신고된 사기 데이터의 결합 분석을 통해 ‘최신 사기 유형 알림, 주요 사기 유형 알림’은 물론 ‘의심번호 통화 중 단계별 경고’, ‘가족보호 알림’ 등의 기능을 제공하여 실제 사기 피해를 예방 및 방지하는 서비스를 말합니다.
	
	2. "제휴사"란 고객이 본 서비스를 가입할 수 있도록 가입채널을 제공하는 기관 또는 사이트를 의미합니다.
	
	3. "이용자"란 본 서비스에 가입한 고객으로서, 회사가 제공하는 서비스를 이용하는 만 65세 이상의 개인을 말합니다.
	
	4. “앱”이란 서비스의 제공 및 이용을 위해 단말기에 설치되는 '스마트피싱보호' 스마트폰 전용 애플리케이션을 말합니다.
	
	5. 본 조 및 본 약관의 다른 조항에서 정의되지 않은 용어는 관계 법령 및 서비스 안내에서 정한 바에 따릅니다.
	
	 
	
	제 3조 (약관의 효력 및 변경)
	
	1. 회사는 이 약관을 회사가 운영하는 사이트나 서비스 신청 화면에 게시하여 이용자에게 공시함으로써 약관의 효력이 발생합니다.
	
	2. 회사는 필요하다고 인정되는 경우 이 약관을 변경할 수 있으며, 회사가 약관을 변경할 경우에는 적용일자 및 변경사유를 명시하여 회사가 운영하는 사이트에서 적용일자 7일 전에 공지합니다.
	
	3. 회사가 제2항에 따라 변경 약관을 공지하였음에도 이용자가 약관 변경 적용일까지 변경 약관에 대한 거부의사를 표시하지 않으면 약관의 변경에 동의한 것으로 간주합니다. 이용자는 변경된 약관 사항에 동의하지 않으면 서비스 이용을 중단하고 이용 계약을 해지할 수 있습니다.
	
	4. 가입을 신청하려는 자(이하 “가입신청자”)가 “약관에 동의”의 체크박스를 체크하였을 경우 본 약관의 내용을 모두 인지하였으며 서비스와 관련하여 회사에서 제공하거나 제공할 모든 서비스에 동의한 것으로 봅니다.
	
	5. 가입신청자 및 이용자가 이 약관의 내용(약관 변경 시 변경된 내용 포함)을 알지 못하여 발생하는 손해 및 피해에 대해서는 회사는 일체 책임을 지지 않습니다.
	
	 
	
	제 4조 (이용 신청 및 이용 계약의 성립)
	
	1. 이용계약은 가입신청자가 약관의 내용에 동의한 후 회원가입을 신청 하고 회사가 이를 승낙함으로써 체결됩니다.
	
	 
	
	제 5조 (서비스 제공)
	
	1. 회사는 정책 및 운영, 기술상의 필요에 따라 서비스를 변경하거나 중단할 수 있으며, 이에 대한 특별한 법률상의 규정이 없는 한 사용자에게 별도의 보상을 하지 않습니다.
	
	2. '이용자'에게 제공되는 서비스는 회사의 서비스 운영 정책 및 운영, 기술상의 이유로 '스마트피싱보호' 유료이용 고객에게 제공되는 서비스 및 기능과 차이가 있을 수 있습니다.
	
	3. 회사는 사용자가 본 약관을 위반하거나 서비스의 운영을 방해한 경우 서비스 이용을 제한할 수 있습니다.
	
	4. 회사가 서비스를 제공하는 기간은 서비스 이용신청을 회사가 승낙한 날로부터 서비스 이용계약의 해지 또는 서비스 종료 때까지입니다.
	
	5. 서비스는 연중무휴 1일 24시간 제공을 원칙으로 합니다.
	
	 
	
	제 6조 (이용신청에 대한 승낙의 유보 및 서비스 이용의 제한)
	
	1. 제 3조에 따라 가입신청자가 서비스 이용을 신청하면 회사는 원칙적으로 이를 승낙합니다. 단, 다음 각 호에 해당하는 신청에 대해서는 그 사유가 해소될 때까지 승낙을 유보하거나 거부할 수 있습니다.
	
	① 기술상 서비스를 제공할 수 없는 경우
	
	② 가입신청자가 서비스 이용요금의 납부를 지체하여 이동통신사 정책에 따른 서비스 이용 정지 대상이 된 경우
	
	③ 가입자의 단말기가 문자 수신(SMS/MMS), 앱 PUSH 수신에 문제가 있는 경우 또는 기타 사유로 문자 전송(SMS/MMS), 앱 PUSH 전송을 받을 수 없는 경우
	
	④ 사용하는 단말기의 운영체제(OS), 기기정보 등을 루팅, 탈옥 등을 통해 임의로 변경한 경우
	
	⑤ 단말기 특성 또는 OS 버전의 문제로 인해 정상적인 서비스 제공이 불가능한 경우
	
	⑥ 가입신청자가 선불 이동전화 요금제 등 서비스를 이용할 수 없는 일부 특수 이동전화 요금제에 가입되어 있거나, 이용자가 해당 요금제로 변경하는 경우
	
	2. 회사는 정기점검 및 기타 기술상의 이유, 기타 운영상의 사유와 목적에 따라 회사가 정한 기간에 일시 중지될 수 있으며, 제휴사의 기술상, 운영상의 사유와 목적에 따라 사전 공지 없이 일시 중지될 수 있습니다
	
	3. 회사는 전시•사변•천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우와 전기통신사업법에 의한 기간통신사업자가 전기통신서비스를 중지하는 등 기타 부득이한 사유가 있는 경우에는 서비스의 전부 또는 일부를 제한하거나 정지할 수 있습니다.
	
	4. 회원이 본 약관에 동의함은 서비스와 관련하여 회사에서 제공하거나 제공할 모든 서비스에 동의하는 것으로 봅니다.
	
	 
	
	제 7조 (서비스 청약 철회)
	
	1. 본 서비스는 디지털컨텐츠로 제공되는 상품이며 게시되는 즉시 상품 이용이 되므로 청약철회가 불가능합니다. 다만 관련 법령이 정한 바에 따라 가입자의 권리 행사가 가능하도록 합니다.
	
	 
	
	제 8조 (청약 철회 등의 효과)
	
	1. 이용자는 회사가 정한 절차에 따라 서비스를 해지할 수 있으며 이 때 즉시 해지됩니다.
	
	2. 서비스 앱 삭제가 서비스 해지를 뜻하는 것은 아닙니다. 해지는 별도 절차이며 인터넷사이트를 이용한 해지 또는 전화를 이용한 해지를 하셔야 합니다.
	
	3. 인터넷을 이용한 해지 : www.antiscam.co.kr의 서비스 해지 메뉴를 통해 해지하실 수 있습니다.
	
	4. 앱을 이용한 해지 : 스마트피싱보호 앱 내 해지 메뉴를 통해 해지하실 수 있습니다.
	
	5. 전화를 이용한 해지 : 고객센터(1811-7531) 또는 이동통신사 고객센터 (휴대폰 +114)에서 해지하실 수 있습니다.
	
	 
	
	제 9조 (정보의 제공 및 광고의 게재)
	
	1. 회사는 서비스를 운영할 때 결제 안내, 정책 변경, 장애 등 필수 주요 내용은 홈페이지에 게재하거나 이메일(E-mail), 문자메시지(SMS/MMS), Push message 등의 방법으로 이용자에게 제공할 수 있습니다.
	
	2. 회사는 서비스를 운영할 때 회사 또는 제휴사의 서비스 관련 각종 광고를 이용자의 동의를 얻어 이메일(e-mail), 문자메시지(SMS/MMS), Push message 등의 방법으로 이용자에게 제공할 수 있습니다.
	
	3. 회사는 서비스 운영을 위해 서비스 내에 회사 또는 3자의 광고를 게재하거나 이용자에게 필요하다고 판단되는 정보를 이메일(E-mail), 문자메시지(SMS/MMS), Push message 등의 방법으로 제공할 수 있으며 사용자는 언제든지 수신을 거부할 수 있습니다.
	
	 
	
	제 10조 (데이터 수집 및 사용)
	
	1. 회사는 업무와 관련하여 이용자의 사전 동의 없이 특정 개인을 알아볼 수 없는 형태로 가공된, 이용자 전체 또는 일부의 정보에 관한 통계자료를 작성하여 사용할 수 있고, 이를 위하여 이용자의 휴대폰 단말에 쿠키(Cookie : 인터넷 웹사이트의 방문기록 정보)를 전송할 수 있습니다. 이때 회원은 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여 경고하도록 사용하는 휴대폰 단말의 브라우저 설정을 변경할 수 있습니다. 쿠키의 설정 변경으로 말미암아 서비스 이용이 변경되는 것은 회원의 책임입니다.
	
	2. 회사는 앱에 포함된 광고의 통계 수집을 위해 광고 노출수와 광고 클릭수 등을 수집합니다.
	
	3. 수집한 데이터는 통계자료로만 활용됩니다.
	
	 
	
	제 11조 (회사의 의무)
	
	1. 회사는 본 약관에 정한 바에 따라 지속적, 안정적으로 서비스를 제공할 의무가 있습니다. 단, 전쟁 지진, 재난 등 천재 지변이나 불가피한 시스템의 고장, 통신서비스 회사의 통신 두절 등 불가항력의 사유가 발생한 경우에는 본 서비스를 중단할 수 있습니다.
	
	2. 회사는 서비스 제공과 관련하여 취득한 이용자 정보를 본인의 사전 승낙 없이 제 3자에게 누설 또는 배포하지 않습니다. 다만, 관계법령에 의한 수사상의 목적으로 관계기관으로부터 요구 받은 경우나 국가기관의 요청이 있는 경우에는 그러하지 않습니다.
	
	3. 회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 이용자와의 계약 관련 절차 및 내용 등에 있어 편의를 제공하도록 노력합니다.
	
	4. 회사는 이용자에게 안정적인 서비스 제공을 위하여 지속적으로 관련 시스템이나 절차, 기능 등의 예방 점검, 유지 보수 등을 이행하며, 회사 내부의 원인에 의해 서비스 장애가 발생하는 경우에는 부득이한 사유가 없는 한 곧바로 이를 수리 및 복구합니다.
	
	5. 회사는 서비스와 관련한 이용자의 불만사항을 접수하면 신속히 처리해야 할 의무가 있습니다.
	
	 
	
	제 12조 (이용자의 의무)
	
	1. 이용자는 본인이 설정한 '서비스' 설정에 책임을 지며, 이용자 부주의로 인해 발생하는 모든 결과에 대한 책임은 이용자에게 있습니다.
	
	2. 이용자는 관계 법령, 본 약관의 규정, 이용안내 및 서비스 상에 공지한 주의사항, 회사가 통지하는 사항 등을 따라야 하며, 기타 회사의 업무에 방해되는 행위를 해서는 안됩니다.
	
	 
	
	제 13조 (이용자의 개인정보 보호)
	
	1. 회사는 관련 법령이 정하는 바에 따라 이용자 등록정보를 포함한 이용자의 개인정보를 보호하기 위하여 노력합니다.
	
	2. 이용자의 개인정보 보호와 관련한 사항은 관계 법령과 회사가 정하는 개인정보처리방침을 따릅니다. 서비스 개인정보처리방침은 회사와 이용자 간 이용계약의 일부를 구성합니다.
	
	제 14조 (손해배상)
	
	1. 회사와 이용자는 일방 당사자의 귀책사유로 인하여 상대방에게 발생한 손해에 대하여 배상해야 합니다.
	
	2. 회사는 본 서비스 이용자의 고의 또는 과실로 발생한 손해에 대해서 어떠한 책임도 지지 않습니다.
	
	 
	
	제 15조 (면책사항)
	
	1. 본 서비스는 사기 예방차원에서 이용자에게 제공되는 서비스로서 이용자를 모든 사기 피해로부터 보호 할 수 없으며, 이용자에게 실제 사기(보이스피싱, 스미싱, 해킹 등)피해가 발생될 시에도 회사는 별도의 보상이나 책임을 지지 않습니다.
	
	2. 회사는 천재지변 또는 이에 준하는 불가항력으로 말미암아 서비스를 제공할 수 없을 떄에는 서비스 제공에 대한 책임이 면제됩니다.
	
	3. 회사는 전기통신서비스 특성상 불가피한 사유로 서비스를 제공할 수 없거나 관련 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 일시적으로 서비스 제공을 중단하는 것에 책임을 지지 않습니다.
	
	4. 회사는 이용자의 고의, 과실 또는 서비스 이용방법에 대한 미숙지 등의 귀책사유로 서비스 이용의 장애, 회원정보나 자료소실 또는 손해가 발생한 것에 책임을 지지 않습니다.
	
	5. 회사는 이용자가 자료의 저장, 공유를 함으로써 발생하는 법적인 문제에 책임을 지지 않습니다.
	
	6. 회사는 이용자 상호 간 또는 이용자와 제 3자 상호간에 서비스를 매개로 발생한 분쟁에 개입할 의무가 없으며 이 때문에 발생한 손해를 배상할 책임도 없습니다.
	
	7. 이용자가 자신의 개인정보를 타인에게 유출 또는 제공하거나 무단으로 사용 당하는 등 그 관리를 소홀히 했을 때 발생하는 피해에 대해 회사는 일절 책임을 지지 않습니다.
	
	8. 회사는 이용자의 휴대폰 단말 오류, 제 3자 제공 프로그램의 종료 및 오류 또는 네트워크 환경상의 장애 및 오류로 인한 파일 손상 또는 업로드 및 다운로드 도중 파일이 잘못 저장된 것에 책임을 지지 않습니다.
	
	9. 이용자의 상태 변경, 이용 계약 해지 또는 요금 미납으로 인해 서비스 이용이 정지되어 이용자에게 손해가 발생하였을 때 회사는 이에 책임을 지지 않습니다.
	
	10. 회사가 공식적으로 제공한 경로가 아닌, 비공식적 경로를 통해 자사의 서비스 앱을 다운로드/설치한 이용자에게 발생한 손해에 대하여 회사는 책임을 지지 않습니다.
	
	11. 공식적 경로를 통하지 않고 설치하거나, 설치된 타 앱에 의한 손해는 회사에 책임을 지지 않습니다.
	
	 
	
	제 16조 (약관 외 준칙)
	
	이 약관에 명시되지 아니한 사항에 대해서는 관계 법령과 회사가 정한 본 서비스의 세부이용지침 등의 규정을 따릅니다.
	
	 
	
	제 17조 (관할법원)
	
	1. 서비스 이용과 관련하여 회사와 이용자 사이에 분쟁이 발생하면 회사와 이용자는 분쟁의 해결을 위해 성실히 협의합니다.
	
	2. 제1항의 협의에서도 분쟁이 해결되지 않으면 양 당사자는 민사소송법상의 관할법원에 소를 제기할 수 있습니다.
	
	 
	
	 
	
	부칙
	
	(시행일) 본 약관은 2020년 4월 28일부터 시행됩니다.`,
}
export const TERMS_USED_LG_PAY = {
  title: `서비스이용약관`,
  desc: `제 1조 (목적)

	본 이용약관은 ㈜데이터유니버스(이하“회사”)에서 제공하는 '스마트피싱보호'(이하 “서비스”)서비스를 이용하는자(이하 "이용자")와 회사 간의 권리, 의무 및 책임사항 등 기본적인 사항의 규정을 목적으로 합니다.
	
	 
	
	제 2조 (용어의 정의)
	
	본 약관에서 사용되는 용어의 정의는 다음과 같습니다.
	
	1. "서비스"란 국내외 인터넷(SNS, 뉴스 등) 상에 있는 사기 관련 빅데이터와 금융기관과 제휴기관 등에 신고된 사기 데이터의 결합 분석을 통해 ‘최신 사기 유형 알림, 주요 사기 유형 알림’은 물론 ‘의심번호 통화 중 단계별 경고’, ‘가족보호 알림’ 등의 기능을 제공하여 실제 사기 피해를 예방 및 방지하는 서비스를 말합니다.
	
	2. "제휴사"란 고객이 본 서비스를 가입할 수 있도록 가입채널을 제공하는 기관 또는 사이트를 의미합니다.
	
	3. "이용자"란 본 서비스에 가입한 고객으로서, 회사가 제공하는 서비스를 이용하고 요금을 지불하는 개인을 말합니다.
	
	4. “월정액”이란 서비스의 이용요금을 서비스 해지 시까지 매월 자동으로 청구, 결제하는 방식을 말합니다.
	
	5. “앱”이란 서비스의 제공 및 이용을 위해 단말기에 설치되는 '스마트피싱보호' 스마트폰 전용 애플리케이션을 말합니다.
	
	6. 본 조 및 본 약관의 다른 조항에서 정의되지 않은 용어는 관계 법령 및 서비스 안내에서 정한 바에 따릅니다.
	
	 
	
	제 3조 (약관의 효력 및 변경)
	
	1. 회사는 이 약관을 회사가 운영하는 사이트나 서비스 신청 화면에 게시하여 이용자에게 공시함으로써 약관의 효력이 발생합니다.
	
	2. 회사는 필요하다고 인정되는 경우 이 약관을 변경할 수 있으며, 회사가 약관을 변경할 경우에는 적용일자 및 변경사유를 명시하여 회사가 운영하는 사이트에서 적용일자 7일 전에 공지합니다.
	
	3. 회사가 제2항에 따라 변경 약관을 공지하였음에도 이용자가 약관 변경 적용일까지 변경 약관에 대한 거부의사를 표시하지 않으면 약관의 변경에 동의한 것으로 간주합니다. 이용자는 변경된 약관 사항에 동의하지 않으면 서비스 이용을 중단하고 이용 계약을 해지할 수 있습니다.
	
	4. 가입을 신청하려는 자(이하 “가입신청자”)가 “약관에 동의”의 체크박스를 체크하였을 경우 본 약관의 내용을 모두 인지하였으며 서비스와 관련하여 회사에서 제공하거나 제공할 모든 서비스에 동의한 것으로 봅니다.
	
	5. 가입신청자 및 이용자가 이 약관의 내용(약관 변경 시 변경된 내용 포함)을 알지 못하여 발생하는 손해 및 피해에 대해서는 회사는 일체 책임을 지지 않습니다.
	
	 
	
	제 4조 (이용 신청 및 이용 계약의 성립)
	
	1. 이용계약은 가입신청자가 약관의 내용에 동의한 후 회원가입을 신청 하고 회사가 이를 승낙함으로써 체결됩니다.
	
	2. 피싱·해킹보상보험은 본 서비스 가입 익일 0시부터 서비스 해지일시까지 적용됩니다.
	
	 
	
	제 5조 (서비스의 내용)
	
	스마트피싱보호란 국내외 인터넷(SNS, 뉴스 등) 상에 있는 사기 관련 빅데이터와 금융기관과 제휴기관 등에 신고된 사기 데이터의 결합 분석을 통해 ‘최신 사기 유형 알림, 주요 사기 유형 알림’은 물론 ‘의심번호 통화 중 단계별 경고’, ‘가족보호 알림’ 등의 기능을 제공하여 실제 사기 피해를 예방 및 방지하는 서비스를 말합니다.
	
	 
	
	제 6조 (서비스 이용 요금 및 제공 시간)
	
	1. 이용요금 : 월정액 1,650원(부가세 포함)
	
	2. 피싱해킹보상보험 : 피싱·해킹금융사기 보상을 위한 기업비용보상보험은 이용요금에 포함되지 않은 ㈜데이터유니버스가 “서비스” 이용 고객에게 무상으로 제공하는 보험이며, 정상적으로 서비스를 제공받는 경우(휴대폰 이용 정지/해지 등 제외)에만 적용됩니다.
	
	3. 결제방식 : 매월 1항의 요금이 이동통신사의 요금고지서에 표시되어 휴대폰 요금에 합산되어 자동 결제됩니다. 서비스 이용요금의 결제방법 및 결제일은 가입자가 사용하는 휴대전화 이동통신사의 정책에 따릅니다.
	
	4. 회사가 서비스를 제공하는 기간은 서비스 이용신청을 회사가 승낙한 날로부터 서비스 이용계약의 해지 또는 서비스 종료 때까지입니다.
	
	5. 서비스는 연중무휴 1일 24시간 제공을 원칙으로 합니다.
	
	 
	
	제 7조 (이용신청에 대한 승낙의 유보 및 서비스 이용의 제한)
	
	1. 제 3조에 따라 가입신청자가 서비스 이용을 신청하면 회사는 원칙적으로 이를 승낙합니다. 단, 다음 각 호에 해당하는 신청에 대해서는 그 사유가 해소될 때까지 승낙을 유보하거나 거부할 수 있습니다.
	
	① 기술상 서비스를 제공할 수 없는 경우
	
	② 가입신청자가 서비스 이용요금의 납부를 지체하여 이동통신사 정책에 따른 서비스 이용 정지 대상이 된 경우
	
	③ 가입자의 단말기가 문자 수신(SMS/MMS), 앱 PUSH 수신에 문제가 있는 경우 또는 기타 사유로 문자 전송(SMS/MMS), 앱 PUSH 전송을 받을 수 없는 경우
	
	④ 사용하는 단말기의 운영체제(OS), 기기정보 등을 루팅, 탈옥 등을 통해 임의로 변경한 경우
	
	⑤ 단말기 특성 또는 OS 버전의 문제로 인해 정상적인 서비스 제공이 불가능한 경우
	
	⑥ 가입신청자가 선불 이동전화 요금제 등 서비스를 이용할 수 없는 일부 특수 이동전화 요금제에 가입되어 있거나, 이용자가 해당 요금제로 변경하는 경우
	
	2. 회사는 정기점검 및 기타 기술상의 이유, 기타 운영상의 사유와 목적에 따라 회사가 정한 기간에 일시 중지될 수 있으며, 제휴사의 기술상, 운영상의 사유와 목적에 따라 사전 공지 없이 일시 중지될 수 있습니다
	
	3. 회사는 전시•사변•천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우와 전기통신사업법에 의한 기간통신사업자가 전기통신서비스를 중지하는 등 기타 부득이한 사유가 있는 경우에는 서비스의 전부 또는 일부를 제한하거나 정지할 수 있습니다.
	
	4. 회원이 본 약관에 동의함은 서비스와 관련하여 회사에서 제공하거나 제공할 모든 서비스에 동의하는 것으로 봅니다.
	
	 
	
	제 8조 (서비스 청약 철회)
	
	1. 회사와 유료서비스 이용에 관한 계약을 체결한 회원은 계약 내용에 관한 서면을 받은 날(그 서면을 받은 때보다 서비스 이용 가능일이 늦은 경우에는 서비스 이용 가능일)부터 7일 이내에 별도의 수수료‧위약금 등의 부담 없이 청약철회를 할 수 있습니다.
	
	2. 회원은 다음 각 호에 해당하는 경우에는 회사의 의사에 반하여 제1항에 따른 청약철회를 할 수 없습니다. 단, 제2호 내지 제4호의 경우, 사전에 청약 철회 제한 사실을 회원이 쉽게 알 수 있는 곳에 명기하거나 서비스 관련 정보를 제공하는 등의 조치를 취하지 않은 경우는 제외됩니다.
	
	① 회원에게 책임이 있는 사유로 재화, 콘텐츠 등(이하 “서비스 등”)이 멸실되거나 훼손된 경우
	
	② 다음 각 목을 포함하여 회원이 서비스 등을 사용 또는 일부 소비한 경우
	
	가. 가입 즉시 사용되거나 적용되는 경우
	
	나. 부가적인 혜택이 제공되는 경우 또는 추가 혜택이 사용되거나 일부가 사용된 경우
	
	다. 용역 또는 디지털콘텐츠의 제공이 개시된 경우
	
	③ 서비스 등의 제공이 개시된 경우.
	
	④ 기타 「전자상거래 등에서의 소비자보호에 관한 법률」 기타 관련 법령에 따라 청약 철회 제한 사유에 해당하는 경우
	
	3. 제1항 및 제2항에도 불구하고 서비스 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 서비스 등을 제공받은 날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.
	
	4. 회원은 구두 또는 서면(전자문서 포함)으로 본 조에 따른 청약철회를 할 수 있습니다.
	
	 
	
	제 9조 (청약 철회 및 해지 등의 효과)
	
	1. 이용자는 회사가 정한 절차에 따라 서비스를 해지할 수 있으며 이 때 즉시 해지됩니다.
	
	2. 서비스가 해지되는 경우, 해지일자를 기준으로 해당월의 서비스 이용료는 일할 계산되어 익월 이동전화 청구서에 청구됩니다.
	
	3. 서비스 앱 삭제가 서비스 해지를 뜻하는 것은 아닙니다. 해지는 별도 절차이며 인터넷사이트를 이용한 해지 또는 전화를 이용한 해지를 하셔야 합니다.
	
	4. 인터넷을 이용한 해지 : www.antiscam.co.kr의 서비스 해지 메뉴를 통해 해지하실 수 있습니다.
	
	5. 앱을 이용한 해지 : 스마트피싱보호 앱 내 해지 메뉴를 통해 해지하실 수 있습니다.
	
	6. 전화를 이용한 해지 : 고객센터(1811-7531) 또는 이동통신사 고객센터 (휴대폰 +114)에서 해지하실 수 있습니다.
	
	7. 회사는 이용자가 제8조 제1항 또는 제3항에 따라 청약철회 등을 한 경우, 청약철회 등을 한 날부터 3영업일 이내에 이미 지급 받은 서비스 등의 대금을 환급하여야 합니다. 이 경우 회사가 이용자에게 서비스 등의 대금 환급을 지연한 때에는 그 지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률 시행령」 제21조의3에서 정하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.
	
	8. 회사는 제1항의 대금을 환급함에 있어서 회원이 신용카드 또는 전자화폐 등의 결제수단으로 서비스 등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 청구를 정지 또는 취소하도록 요청합니다. 단, 회사의 내부 환불 지급 정책에 따라 이용자가 대금을 지급한 결제수단 이외의 방법으로 환불할 수 있으며 이 경우 이용자에게 고지합니다.
	
	 
	
	제 10조 (정보의 제공 및 광고의 게재)
	
	1. 회사는 서비스를 운영할 때 결제 안내, 정책 변경, 장애 등 필수 주요 내용은 홈페이지에 게재하거나 이메일(E-mail), 문자메시지(SMS/MMS), Push message 등의 방법으로 이용자에게 제공할 수 있습니다.
	
	2. 회사는 서비스를 운영할 때 회사 또는 제휴사의 서비스 관련 각종 광고를 이용자의 동의를 얻어 이메일(e-mail), 문자메시지(SMS/MMS), Push message 등의 방법으로 이용자에게 제공할 수 있습니다.
	
	 
	
	제 11조 (데이터 수집 및 사용)
	
	1. 회사는 업무와 관련하여 이용자의 사전 동의 없이 특정 개인을 알아볼 수 없는 형태로 가공된, 이용자 전체 또는 일부의 정보에 관한 통계자료를 작성하여 사용할 수 있고, 이를 위하여 이용자의 휴대폰 단말에 쿠키(Cookie : 인터넷 웹사이트의 방문기록 정보)를 전송할 수 있습니다. 이때 회원은 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여 경고하도록 사용하는 휴대폰 단말의 브라우저 설정을 변경할 수 있습니다. 쿠키의 설정 변경으로 말미암아 서비스 이용이 변경되는 것은 회원의 책임입니다.
	
	2. 회사는 앱에 포함된 광고의 통계 수집을 위해 광고 노출수와 광고 클릭수 등을 수집합니다.
	
	3. 수집한 데이터는 통계자료로만 활용됩니다.
	
	 
	
	제 12조 (회사의 의무)
	
	1. 회사는 본 약관에 정한 바에 따라 지속적, 안정적으로 서비스를 제공할 의무가 있습니다. 단, 전쟁 지진, 재난 등 천재 지변이나 불가피한 시스템의 고장, 통신서비스 회사의 통신 두절 등 불가항력의 사유가 발생한 경우에는 본 서비스를 중단할 수 있습니다.
	
	2. 회사는 서비스 제공과 관련하여 취득한 이용자 정보를 본인의 사전 승낙 없이 제 3자에게 누설 또는 배포하지 않습니다. 다만, 관계법령에 의한 수사상의 목적으로 관계기관으로부터 요구 받은 경우나 국가기관의 요청이 있는 경우에는 그러하지 않습니다.
	
	3. 회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 이용자와의 계약 관련 절차 및 내용 등에 있어 편의를 제공하도록 노력합니다.
	
	4. 회사는 이용자에게 안정적인 서비스 제공을 위하여 지속적으로 관련 시스템이나 절차, 기능 등의 예방 점검, 유지 보수 등을 이행하며, 회사 내부의 원인에 의해 서비스 장애가 발생하는 경우에는 부득이한 사유가 없는 한 곧바로 이를 수리 및 복구합니다.
	
	5. 회사는 서비스와 관련한 이용자의 불만사항을 접수하면 신속히 처리해야 할 의무가 있습니다.
	
	 
	
	제 13조 (이용자의 의무)
	
	1. 이용자는 본인이 설정한 '서비스' 설정에 책임을 지며, 이용자 부주의로 인해 발생하는 모든 결과에 대한 책임은 이용자에게 있습니다.
	
	2. 이용자는 관계 법령, 본 약관의 규정, 이용안내 및 서비스 상에 공지한 주의사항, 회사가 통지하는 사항 등을 따라야 하며, 기타 회사의 업무에 방해되는 행위를 해서는 안됩니다.
	
	 
	
	제 14조 (이용자의 개인정보 보호)
	
	1. 회사는 관련 법령이 정하는 바에 따라 이용자 등록정보를 포함한 이용자의 개인정보를 보호하기 위하여 노력합니다.
	
	2. 이용자의 개인정보 보호와 관련한 사항은 관계 법령과 회사가 정하는 개인정보처리방침을 따릅니다. 서비스 개인정보처리방침은 회사와 이용자 간 이용계약의 일부를 구성합니다.
	
	 
	
	제 15조 (피싱해킹금융사기보상-기업비용보상보험)
	
	1. 회사가 보험계약을 체결하며, 보험 계약과 관련된 모든 권리 의무는 회사가 행사합니다. 보험료는 전액 회사가 부담하며, 보험금 지급 사유 발생 시 서비스 이용자 1인 1사고당 보험한도액은 300만원입니다. 보험금 지급 시 자기부담금(60만원)이 발생하며, 증권상 최대보상한도액 초과 시 보험금이 지급되지 않을 수 있습니다.
	
	2. 피싱해킹금융사기 보상을 위한 기업비용보상보험은 이용요금과 무관한 보험이며, 보험한도액 및 자기부담금은 사고율에 따라 증감이 될 수 있고 보험계약이 철회될 수 있습니다.
	
	 
	
	제 16조 (손해배상)
	
	1. 회사와 이용자는 일방 당사자의 귀책사유로 인하여 상대방에게 발생한 손해에 대하여 배상해야 합니다.
	
	2. 회사는 본 서비스 이용자의 고의 또는 과실로 발생한 손해에 대해서 어떠한 책임도 지지 않습니다.
	
	 
	
	제 17조 (면책사항)
	
	1. 회사는 천재지변 또는 이에 준하는 불가항력으로 말미암아 서비스를 제공할 수 없을 떄에는 서비스 제공에 대한 책임이 면제됩니다.
	
	2. 회사는 전기통신서비스 특성상 불가피한 사유로 서비스를 제공할 수 없거나 관련 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 일시적으로 서비스 제공을 중단하는 것에 책임을 지지 않습니다.
	
	3. 회사는 이용자의 고의, 과실 또는 서비스 이용방법에 대한 미숙지 등의 귀책사유로 서비스 이용의 장애, 회원정보나 자료소실 또는 손해가 발생한 것에 책임을 지지 않습니다.
	
	4. 회사는 이용자가 자료의 저장, 공유를 함으로써 발생하는 법적인 문제에 책임을 지지 않습니다.
	
	5. 회사는 이용자 상호 간 또는 이용자와 제 3자 상호간에 서비스를 매개로 발생한 분쟁에 개입할 의무가 없으며 이 때문에 발생한 손해를 배상할 책임도 없습니다.
	
	6. 이용자가 자신의 개인정보를 타인에게 유출 또는 제공하거나 무단으로 사용 당하는 등 그 관리를 소홀히 했을 때 발생하는 피해에 대해 회사는 일절 책임을 지지 않습니다.
	
	7. 회사는 이용자의 휴대폰 단말 오류, 제 3자 제공 프로그램의 종료 및 오류 또는 네트워크 환경상의 장애 및 오류로 인한 파일 손상 또는 업로드 및 다운로드 도중 파일이 잘못 저장된 것에 책임을 지지 않습니다.
	
	8. 이용자의 상태 변경, 이용 계약 해지 또는 요금 미납으로 인해 서비스 이용이 정지되어 이용자에게 손해가 발생하였을 때 회사는 이에 책임을 지지 않습니다.
	
	9. 회사가 공식적으로 제공한 경로가 아닌, 비공식적 경로를 통해 자사의 서비스 앱을 다운로드/설치한 이용자에게 발생한 손해에 대하여 회사는 책임을 지지 않습니다.
	
	10. 공식적 경로를 통하지 않고 설치하거나, 설치된 타 앱에 의한 손해는 회사에 책임을 지지 않습니다.
	
	 
	
	제 18조 (약관 외 준칙)
	
	이 약관에 명시되지 아니한 사항에 대해서는 관계 법령과 회사가 정한 본 서비스의 세부이용지침 등의 규정을 따릅니다.
	
	 
	
	제 19조 (관할법원)
	
	1. 서비스 이용과 관련하여 회사와 이용자 사이에 분쟁이 발생하면 회사와 이용자는 분쟁의 해결을 위해 성실히 협의합니다.
	
	2. 제1항의 협의에서도 분쟁이 해결되지 않으면 양 당사자는 민사소송법상의 관할법원에 소를 제기할 수 있습니다.
	
	 
	
	 
	
	부칙
	
	(시행일) 본 약관은 2021년 11월 28일부터 시행됩니다.`,
}

export const TERMS_USED_LG_FREE = {
  title: `서비스이용약관`,
  desc: `제 1조 (목적)

	본 이용약관은 ㈜데이터유니버스(이하“회사”)에서 제공하는 '스마트피싱보호'(이하 “서비스”)서비스를 만 65세 이상 무료로 이용하는자(이하 "이용자")와 회사 간의 권리, 의무 및 책임사항 등 기본적인 사항의 규정을 목적으로 합니다.
	
	 
	
	제 2조 (용어의 정의)
	
	본 약관에서 사용되는 용어의 정의는 다음과 같습니다.
	
	1. "서비스"란 국내외 인터넷(SNS, 뉴스 등) 상에 있는 사기 관련 빅데이터와 금융기관과 제휴기관 등에 신고된 사기 데이터의 결합 분석을 통해 ‘최신 사기 유형 알림, 주요 사기 유형 알림’은 물론 ‘의심번호 통화 중 단계별 경고’, ‘가족보호 알림’ 등의 기능을 제공하여 실제 사기 피해를 예방 및 방지하는 서비스를 말합니다.
	
	2. "제휴사"란 고객이 본 서비스를 가입할 수 있도록 가입채널을 제공하는 기관 또는 사이트를 의미합니다.
	
	3. "이용자"란 본 서비스에 가입한 고객으로서, 회사가 제공하는 서비스를 이용하는 만 65세 이상의 개인을 말합니다.
	
	4. “앱”이란 서비스의 제공 및 이용을 위해 단말기에 설치되는 '스마트피싱보호' 스마트폰 전용 애플리케이션을 말합니다.
	
	5. 본 조 및 본 약관의 다른 조항에서 정의되지 않은 용어는 관계 법령 및 서비스 안내에서 정한 바에 따릅니다.
	
	 
	
	제 3조 (약관의 효력 및 변경)
	
	1. 회사는 이 약관을 회사가 운영하는 사이트나 서비스 신청 화면에 게시하여 이용자에게 공시함으로써 약관의 효력이 발생합니다.
	
	2. 회사는 필요하다고 인정되는 경우 이 약관을 변경할 수 있으며, 회사가 약관을 변경할 경우에는 적용일자 및 변경사유를 명시하여 회사가 운영하는 사이트에서 적용일자 7일 전에 공지합니다.
	
	3. 회사가 제2항에 따라 변경 약관을 공지하였음에도 이용자가 약관 변경 적용일까지 변경 약관에 대한 거부의사를 표시하지 않으면 약관의 변경에 동의한 것으로 간주합니다. 이용자는 변경된 약관 사항에 동의하지 않으면 서비스 이용을 중단하고 이용 계약을 해지할 수 있습니다.
	
	4. 가입을 신청하려는 자(이하 “가입신청자”)가 “약관에 동의”의 체크박스를 체크하였을 경우 본 약관의 내용을 모두 인지하였으며 서비스와 관련하여 회사에서 제공하거나 제공할 모든 서비스에 동의한 것으로 봅니다.
	
	5. 가입신청자 및 이용자가 이 약관의 내용(약관 변경 시 변경된 내용 포함)을 알지 못하여 발생하는 손해 및 피해에 대해서는 회사는 일체 책임을 지지 않습니다.
	
	 
	
	제 4조 (이용 신청 및 이용 계약의 성립)
	
	1. 이용계약은 가입신청자가 약관의 내용에 동의한 후 회원가입을 신청 하고 회사가 이를 승낙함으로써 체결됩니다.
	
	 
	
	제 5조 (서비스 제공)
	
	1. 회사는 정책 및 운영, 기술상의 필요에 따라 서비스를 변경하거나 중단할 수 있으며, 이에 대한 특별한 법률상의 규정이 없는 한 사용자에게 별도의 보상을 하지 않습니다.
	
	2. '이용자'에게 제공되는 서비스는 회사의 서비스 운영 정책 및 운영, 기술상의 이유로 '스마트피싱보호' 유료이용 고객에게 제공되는 서비스 및 기능과 차이가 있을 수 있습니다.
	
	3. 회사는 사용자가 본 약관을 위반하거나 서비스의 운영을 방해한 경우 서비스 이용을 제한할 수 있습니다.
	
	4. 회사가 서비스를 제공하는 기간은 서비스 이용신청을 회사가 승낙한 날로부터 서비스 이용계약의 해지 또는 서비스 종료 때까지입니다.
	
	5. 서비스는 연중무휴 1일 24시간 제공을 원칙으로 합니다.
	
	 
	
	제 6조 (이용신청에 대한 승낙의 유보 및 서비스 이용의 제한)
	
	1. 제 3조에 따라 가입신청자가 서비스 이용을 신청하면 회사는 원칙적으로 이를 승낙합니다. 단, 다음 각 호에 해당하는 신청에 대해서는 그 사유가 해소될 때까지 승낙을 유보하거나 거부할 수 있습니다.
	
	① 기술상 서비스를 제공할 수 없는 경우
	
	② 가입신청자가 서비스 이용요금의 납부를 지체하여 이동통신사 정책에 따른 서비스 이용 정지 대상이 된 경우
	
	③ 가입자의 단말기가 문자 수신(SMS/MMS), 앱 PUSH 수신에 문제가 있는 경우 또는 기타 사유로 문자 전송(SMS/MMS), 앱 PUSH 전송을 받을 수 없는 경우
	
	④ 사용하는 단말기의 운영체제(OS), 기기정보 등을 루팅, 탈옥 등을 통해 임의로 변경한 경우
	
	⑤ 단말기 특성 또는 OS 버전의 문제로 인해 정상적인 서비스 제공이 불가능한 경우
	
	⑥ 가입신청자가 선불 이동전화 요금제 등 서비스를 이용할 수 없는 일부 특수 이동전화 요금제에 가입되어 있거나, 이용자가 해당 요금제로 변경하는 경우
	
	2. 회사는 정기점검 및 기타 기술상의 이유, 기타 운영상의 사유와 목적에 따라 회사가 정한 기간에 일시 중지될 수 있으며, 제휴사의 기술상, 운영상의 사유와 목적에 따라 사전 공지 없이 일시 중지될 수 있습니다
	
	3. 회사는 전시•사변•천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우와 전기통신사업법에 의한 기간통신사업자가 전기통신서비스를 중지하는 등 기타 부득이한 사유가 있는 경우에는 서비스의 전부 또는 일부를 제한하거나 정지할 수 있습니다.
	
	4. 회원이 본 약관에 동의함은 서비스와 관련하여 회사에서 제공하거나 제공할 모든 서비스에 동의하는 것으로 봅니다.
	
	 
	
	제 7조 (서비스 청약 철회)
	
	1. 본 서비스는 디지털컨텐츠로 제공되는 상품이며 게시되는 즉시 상품 이용이 되므로 청약철회가 불가능합니다. 다만 관련 법령이 정한 바에 따라 가입자의 권리 행사가 가능하도록 합니다.
	
	 
	
	제 8조 (청약 철회 등의 효과)
	
	1. 이용자는 회사가 정한 절차에 따라 서비스를 해지할 수 있으며 이 때 즉시 해지됩니다.
	
	2. 서비스 앱 삭제가 서비스 해지를 뜻하는 것은 아닙니다. 해지는 별도 절차이며 인터넷사이트를 이용한 해지 또는 전화를 이용한 해지를 하셔야 합니다.
	
	3. 인터넷을 이용한 해지 : www.antiscam.co.kr의 서비스 해지 메뉴를 통해 해지하실 수 있습니다.
	
	4. 앱을 이용한 해지 : 스마트피싱보호 앱 내 해지 메뉴를 통해 해지하실 수 있습니다.
	
	5. 전화를 이용한 해지 : 고객센터(1811-7531) 또는 이동통신사 고객센터 (휴대폰 +114)에서 해지하실 수 있습니다.
	
	 
	
	제 9조 (정보의 제공 및 광고의 게재)
	
	1. 회사는 서비스를 운영할 때 결제 안내, 정책 변경, 장애 등 필수 주요 내용은 홈페이지에 게재하거나 이메일(E-mail), 문자메시지(SMS/MMS), Push message 등의 방법으로 이용자에게 제공할 수 있습니다.
	
	2. 회사는 서비스를 운영할 때 회사 또는 제휴사의 서비스 관련 각종 광고를 이용자의 동의를 얻어 이메일(e-mail), 문자메시지(SMS/MMS), Push message 등의 방법으로 이용자에게 제공할 수 있습니다.
	
	3. 회사는 서비스 운영을 위해 서비스 내에 회사 또는 3자의 광고를 게재하거나 이용자에게 필요하다고 판단되는 정보를 이메일(E-mail), 문자메시지(SMS/MMS), Push message 등의 방법으로 제공할 수 있으며 사용자는 언제든지 수신을 거부할 수 있습니다.
	
	 
	
	제 10조 (데이터 수집 및 사용)
	
	1. 회사는 업무와 관련하여 이용자의 사전 동의 없이 특정 개인을 알아볼 수 없는 형태로 가공된, 이용자 전체 또는 일부의 정보에 관한 통계자료를 작성하여 사용할 수 있고, 이를 위하여 이용자의 휴대폰 단말에 쿠키(Cookie : 인터넷 웹사이트의 방문기록 정보)를 전송할 수 있습니다. 이때 회원은 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여 경고하도록 사용하는 휴대폰 단말의 브라우저 설정을 변경할 수 있습니다. 쿠키의 설정 변경으로 말미암아 서비스 이용이 변경되는 것은 회원의 책임입니다.
	
	2. 회사는 앱에 포함된 광고의 통계 수집을 위해 광고 노출수와 광고 클릭수 등을 수집합니다.
	
	3. 수집한 데이터는 통계자료로만 활용됩니다.
	
	 
	
	제 11조 (회사의 의무)
	
	1. 회사는 본 약관에 정한 바에 따라 지속적, 안정적으로 서비스를 제공할 의무가 있습니다. 단, 전쟁 지진, 재난 등 천재 지변이나 불가피한 시스템의 고장, 통신서비스 회사의 통신 두절 등 불가항력의 사유가 발생한 경우에는 본 서비스를 중단할 수 있습니다.
	
	2. 회사는 서비스 제공과 관련하여 취득한 이용자 정보를 본인의 사전 승낙 없이 제 3자에게 누설 또는 배포하지 않습니다. 다만, 관계법령에 의한 수사상의 목적으로 관계기관으로부터 요구 받은 경우나 국가기관의 요청이 있는 경우에는 그러하지 않습니다.
	
	3. 회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 이용자와의 계약 관련 절차 및 내용 등에 있어 편의를 제공하도록 노력합니다.
	
	4. 회사는 이용자에게 안정적인 서비스 제공을 위하여 지속적으로 관련 시스템이나 절차, 기능 등의 예방 점검, 유지 보수 등을 이행하며, 회사 내부의 원인에 의해 서비스 장애가 발생하는 경우에는 부득이한 사유가 없는 한 곧바로 이를 수리 및 복구합니다.
	
	5. 회사는 서비스와 관련한 이용자의 불만사항을 접수하면 신속히 처리해야 할 의무가 있습니다.
	
	 
	
	제 12조 (이용자의 의무)
	
	1. 이용자는 본인이 설정한 '서비스' 설정에 책임을 지며, 이용자 부주의로 인해 발생하는 모든 결과에 대한 책임은 이용자에게 있습니다.
	
	2. 이용자는 관계 법령, 본 약관의 규정, 이용안내 및 서비스 상에 공지한 주의사항, 회사가 통지하는 사항 등을 따라야 하며, 기타 회사의 업무에 방해되는 행위를 해서는 안됩니다.
	
	 
	
	제 13조 (이용자의 개인정보 보호)
	
	1. 회사는 관련 법령이 정하는 바에 따라 이용자 등록정보를 포함한 이용자의 개인정보를 보호하기 위하여 노력합니다.
	
	2. 이용자의 개인정보 보호와 관련한 사항은 관계 법령과 회사가 정하는 개인정보처리방침을 따릅니다. 서비스 개인정보처리방침은 회사와 이용자 간 이용계약의 일부를 구성합니다.
	
	제 14조 (손해배상)
	
	1. 회사와 이용자는 일방 당사자의 귀책사유로 인하여 상대방에게 발생한 손해에 대하여 배상해야 합니다.
	
	2. 회사는 본 서비스 이용자의 고의 또는 과실로 발생한 손해에 대해서 어떠한 책임도 지지 않습니다.
	
	 
	
	제 15조 (면책사항)
	
	1. 본 서비스는 사기 예방차원에서 이용자에게 제공되는 서비스로서 이용자를 모든 사기 피해로부터 보호 할 수 없으며, 이용자에게 실제 사기(보이스피싱, 스미싱, 해킹 등)피해가 발생될 시에도 회사는 별도의 보상이나 책임을 지지 않습니다.
	
	2. 회사는 천재지변 또는 이에 준하는 불가항력으로 말미암아 서비스를 제공할 수 없을 떄에는 서비스 제공에 대한 책임이 면제됩니다.
	
	3. 회사는 전기통신서비스 특성상 불가피한 사유로 서비스를 제공할 수 없거나 관련 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 일시적으로 서비스 제공을 중단하는 것에 책임을 지지 않습니다.
	
	4. 회사는 이용자의 고의, 과실 또는 서비스 이용방법에 대한 미숙지 등의 귀책사유로 서비스 이용의 장애, 회원정보나 자료소실 또는 손해가 발생한 것에 책임을 지지 않습니다.
	
	5. 회사는 이용자가 자료의 저장, 공유를 함으로써 발생하는 법적인 문제에 책임을 지지 않습니다.
	
	6. 회사는 이용자 상호 간 또는 이용자와 제 3자 상호간에 서비스를 매개로 발생한 분쟁에 개입할 의무가 없으며 이 때문에 발생한 손해를 배상할 책임도 없습니다.
	
	7. 이용자가 자신의 개인정보를 타인에게 유출 또는 제공하거나 무단으로 사용 당하는 등 그 관리를 소홀히 했을 때 발생하는 피해에 대해 회사는 일절 책임을 지지 않습니다.
	
	8. 회사는 이용자의 휴대폰 단말 오류, 제 3자 제공 프로그램의 종료 및 오류 또는 네트워크 환경상의 장애 및 오류로 인한 파일 손상 또는 업로드 및 다운로드 도중 파일이 잘못 저장된 것에 책임을 지지 않습니다.
	
	9. 이용자의 상태 변경, 이용 계약 해지 또는 요금 미납으로 인해 서비스 이용이 정지되어 이용자에게 손해가 발생하였을 때 회사는 이에 책임을 지지 않습니다.
	
	10. 회사가 공식적으로 제공한 경로가 아닌, 비공식적 경로를 통해 자사의 서비스 앱을 다운로드/설치한 이용자에게 발생한 손해에 대하여 회사는 책임을 지지 않습니다.
	
	11. 공식적 경로를 통하지 않고 설치하거나, 설치된 타 앱에 의한 손해는 회사에 책임을 지지 않습니다.
	
	 
	
	제 16조 (약관 외 준칙)
	
	이 약관에 명시되지 아니한 사항에 대해서는 관계 법령과 회사가 정한 본 서비스의 세부이용지침 등의 규정을 따릅니다.
	
	 
	
	제 17조 (관할법원)
	
	1. 서비스 이용과 관련하여 회사와 이용자 사이에 분쟁이 발생하면 회사와 이용자는 분쟁의 해결을 위해 성실히 협의합니다.
	
	2. 제1항의 협의에서도 분쟁이 해결되지 않으면 양 당사자는 민사소송법상의 관할법원에 소를 제기할 수 있습니다.
	
	 
	
	 
	
	부칙
	
	(시행일) 본 약관은 2020년 4월 28일부터 시행됩니다.`,
}

export const TERMS_PERSONAL_TEL = {
  title: `개인정보 수집 및 이용 동의`,
  desc: `주식회사 데이터유니버스(이하 “회사”)는 이용자의 개인정보를 중요시하며, 『개인정보보호법』을 준수하며, 관련 법령에 의거한 개인정보 처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.


	<b>제 1조 개인정보의 수집 및 이용 목적</b>
	
	회사는 다음의 목적을 위하여 개인정보를 수집하며 목적 이외의 용도로는 이용하지 않으며, 이용 목적이 변경되는 경우에는 『개인정보 보호법』 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
	
	 
	가. 개인정보 수집 항목 (필수)
	
	[서비스 이용]
	
	- 휴대폰 가입통신사, 휴대폰 번호, 이메일, 서비스 이용 정보
	
	- 서비스 접속 일시, 가입경로, 서비스 이용기록, SMS/MMS & Push 전송 기록

	
	[홈페이지 고객 문의]
	
	- 휴대폰 가입통신사, 휴대폰 번호, 이메일, 문의내용
	
	
	나. 이용 목적
	
	스마트피싱보호(Anti-Scam)(이하 “서비스”)의 제공, 유지, 관리, 서비스 분석을 위한 자료 활용, 분쟁 해결, 민원 처리, 피싱해킹보상보험제공, 법령상 의무이행, 회사의 개인정보 처리업무

	 
	<b>제 2조 개인정보의 처리 및 보유기간</b>
	
	회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.
	 
	가. 본 서비스 가입일부터 해지 후 정산완료 시 까지
	
	나. 서비스 이용 목적을 달성한 경우 개인정보를 지체없이 파기합니다. 단, 상법, 국세기본법, 소비자보호에 관한 법률 등 관계 법령에 따라 보존할 필요가 있는 경우 회사는 관계법령에서 
	정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 다음과 같습니다.
                
	<table class="terms_table">
                <tbody><tr><th>보존항목/내용</th><th>보존기간</th><th>보존근거/사유</th>
                    </tr>                
                    <tr>
                        <td>거래에 관한 장부 및 증빙서류</td>
                        <td>5년</td>
                        <td>국세기본법, 법인세법, 부가가치세법 등</td>
                    </tr>
                    <tr>
                        <td>대금결제 및 재화 등의 공급에 관한 기록</td>
                        <td>5년</td>
                        <td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
                    </tr>
                    <tr>
                        <td>본인확인에 관한 기록</td>
                        <td>6개월</td>
                        <td>정보통신 이용촉진 및 정보보호 등에 관한 법률</td>
                    </tr>
                    <tr>
                        <td>계약 또는 청약철회 등에 관한 기록</td>
                        <td>5년</td>
                        <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                    </tr>
                    <tr>
                        <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
                        <td>3년</td>
                        <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                    </tr>           
                </tbody>
</table>
※ 그 외, 법률에 정해진 개인정보 보유기간이 있을 경우 그에 따름

* 개인 정보 수집 및 이용에 동의하지 않을 수 있으나, 동의하지 않은 경우 본 서비스 이용이 불가능할 수 있습니다.
 `,
}

export const TERMS_MARKETING = {
  title: `마케팅 정보 수신 동의`,
  desc: `스마트피싱보호(ANTISCAM)와 관련하여, ㈜데이터유니버스는 다음과 같이 개인정보 수집 및 이용하고, 고객님께 전자적 전송 매체를 통하여 서비스 관련 알림과 마케팅 등의 목적으로 개인에게 최적화된 광고 또는 정보를 전송하고자 합니다

	1. 구분 : 선택 항목
	
	2. 수집 및 이용항목 : 이동전화번호, 동의 경로/일시, 푸시ID
	
	3. 이용 목적
	
	1) 서비스 관련 알림 및 서비스 개발/개선, 서비스 관련 문의 등에 대한 개인 맞춤 상담과 정보 제공
	
	2) 신규 서비스나 이벤트, 제3자의 상품 또는 “스마트피싱보호” 관련 개인 맞춤형 광고 및 정보 전송
	
	4. 보유 및 이용 기간
	
	1) 별도 동의 철회 시까지
	
	2) 단, 관련 법령의 규정에 따라 보전할 필요가 있을 경우, 해당 기간까지 보존함
	
	5. 수신 설정
	
	1) 서비스 가입 시 설정
	
	2) 스마트피싱보호 고객센터 : 1811-7531
	
	※ 고객님께서는 개인정보 제공에 동의하지 않으실 수 있으시며, 동의하지 않더라도 스마트피싱보호 서비스 이용이 가능합니다.
	
	※ 법령에 따른 개인정보의 수집/이용, 계약의 이행/편의 증진을 위한 개인정보 취급위탁 및 개인정보 처리와 관련된 일반 사항은 스마트피싱보호의 개인정보취급방침에 따릅니다.
	
	※ 스마트피싱보호에서 고객이 수집 및 이용에 동의한 개인정보를 활용하여 전자적 전송매체(SMS/MMS/e-maail/App Push 등 다양한 전송 매체)를 통하여 ㈜데이터유니버스 및 제3자 상품 또는 서비스에 대한 개인 맞춤형 광고 정보를 전송할 수 있습니다.
	
	※ 고객이 본 수신동의를 철회하고자 할 경우 고객센터를 통하여 수신동의 철회 요청을 할 수 있습니다.
	
	(시행일) 본 약관은 2020년 10월 08일부터 시행합니다.`,
}

export const TERMS_KT_RECVEIVE = {
  title: `KT 개인정보의 제3자 제공동의`,
  desc: `	KT(이하 '회사')는 스마트피싱보호서비스(ANTI-SCAM)(이하 '서비스')의 원활한 진행을 위해 다음과 같은 정보를 ㈜데이터유니버스에 제공합니다.
	<table class="terms_table">
		<tbody>
			<tr>
				<th>제공받는자</th>
				<td>㈜데이터유니버스</td>
			</tr>
			<tr>
				<th>제공목적</th>
				<td>서비스 가입 및 해지, 서비스 제공, 월정액 정산, 고객민원처리</td>
			</tr><tr>
				<th>제공하는 개인정보</th>
				<td>서비스 가입여부(가입,정지,해지), 고객상태(가입,정지,해지), 고객구분(개인/법인/개인사업자), 단말모델명, 이동전화번호, 고객정보(성별,나이,지역), 미성년자여부, 선불폰여부, 부가서비스 가입상태, 알뜰폰 여부</td>									
			</tr>                
			<tr>
				<th>제공받는자의 개인정보 보유기간</th>									
				<td>서비스 가입기간(가입일~해지일) 동안 이용하고, 요금정산, 요금 과오납 등 분쟁 대비를 위해 해지후 6개월까지 보유.(요금 미/과납이 있을 경우 해결시 까지 보유)</td>
			</tr>
		</tbody>
	</table>

	고객님께서는 개인정보 제공에 동의하지 않으실 수 있으시며, 동의하지 않으신 경우 본 서비스를 이용하실 수 없습니다.

	회사는 상기 정보와 목적 외에는 가입자의 정보를 제공하지 않으며, 서비스 이용을 위한 목적 외에는 고객정보를 활용하지 않습니다.`,
}

export const TERMS_RECEIVE_OTHER = {
  title: `개인정보의 제3자 제공동의`,
  desc: `㈜데이터유니버스는 '스마트피싱보호' 서비스의 가족보호알림 기능의 원활한 이용을 위하여 다음 같은 정보를 제3자에게 제공합니다.

	<table class="terms_table">
        <thead>
          <tr>
            <th>제공받는 자</th>
            <th>제공목적</th>
            <th>제공항목</th>
            <th>보유기간</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              - 이동통신사(SKT, KT, LG U+)<br/>
              - KT망을 임대하여 이동통신 서비스를 제공하는 협정사업자(MVNO)
            </td>
            <td>
              부가서비스 가입/해지,<br/>
              서비스 제공, 월정액 정산
            </td>
            <td>
              휴대폰 번호
            </td>
            <td>
              서비스 해지 후 정산 완료 시까지<br/>
              (단, 법령에 정해진 개인정보 보유기간이 있을 경우 그에 따름)
            </td>
          </tr>
          <tr>
            <td>가족피싱알림 설정에 따른 알림 수신자</td>
            <td>가족보호를 위한 알림 제공</td>
            <td>의심번호 수신 알림</td>
            <td>서비스 해지시까지</td>
          </tr>
        </tbody>
	</table>

	※ 단 법령에서 따로 정하는 경우에는 해당 기간까지 보유합니다.

※ 고객님께서는 개인정보 제공에 동의하지 않으실 수 있으시며, 동의하지 않으신 경우 본 서비스를 이용하실 수 없습니다.
 
※ 회사는 상기 정보와 목적 외에는 가입자의 정보를 제공하지 않으며, 서비스 이용을 위한 목적 외에는 고객정보를 활용하지 않습니다.

(시행일) 본 약관은 2024년 06월 03일부터 시행합니다.
	`,
}

export const TERMS_INSURANCE = {
  title: `피싱해킹금융사기보상-기업비용보상보험 가입 동의`,
  desc: `㈜데이터유니버스(이하’회사’)는 ‘스마트피싱보호’ 서비스(이하’서비스’)의 고객을 대상으로 ‘기업비용보상보험’을 체결합니다.

	피싱해킹금융사기를 보상하는 ‘기업비용보상보험’은 ‘회사’가 보험계약을 체결하며, 보험 계약과 관련된 모든 권리 의무는 ‘회사’가 행사합니다. ‘회사’가 ‘서비스’ 이용고객에게 이용요금에 포함하지 않고 무상으로 제공하는 보험이며, 정상적으로 ‘서비스’를 제공받는 경우(휴대폰 이용 정지/해지, 무료이용고객 제외)에만 적용됩니다.
	피싱해킹금융사기를 보상하는 ‘기업비용보상보험’은 본 서비스 가입 익일 0시부터 서비스 해지일시까지 적용됩니다.
	보험료는 전액 ‘회사’가 부담하며, 보험금 지급사유 발생 시 서비스 이용자 1인 1사고당 보험한도액은 300만원입니다. 보험금 지급 시 자기부담금(60만원)이 발생하며, 증권상 최대보상한도액 초과 시 보험금이 지급되지 않을 수 있습니다.
	피싱해킹금융사기를 보상하는 ‘기업비용보상보험’의 보험한도액 및 자기부담금은 사고율에 따라 증감이 될 수 있으며 보험계약이 철회될 수 있습니다.`,
}

export const TERMS_RECEIVE_OTHER_LG = {
  title: `개인정보의 제3자 제공동의`,
  desc: `㈜데이터유니버스는 '스마트피싱보호' 서비스의 가족보호알림 기능의 원활한 이용을 위하여 다음 같은 정보를 제3자에게 제공합니다.

	<table class="terms_table">
        <thead>
          <tr>
            <th>제공받는 자</th>
            <th>제공목적</th>
            <th>제공항목</th>
            <th>보유기간</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              - 이동통신사(SKT, KT, LG U+)<br/>
              - KT망을 임대하여 이동통신 서비스를 제공하는 협정사업자(MVNO)
            </td>
            <td>
              부가서비스 가입/해지,<br/>
              서비스 제공, 월정액 정산
            </td>
            <td>
              휴대폰 번호
            </td>
            <td>
              서비스 해지 후 정산 완료 시까지<br/>
              (단, 법령에 정해진 개인정보 보유기간이 있을 경우 그에 따름)
            </td>
          </tr>
          <tr>
            <td>가족피싱알림 설정에 따른 알림 수신자</td>
            <td>가족보호를 위한 알림 제공</td>
            <td>의심번호 수신 알림</td>
            <td>서비스 해지시까지</td>
          </tr>
        </tbody>
	</table>

	※ 단 법령에서 따로 정하는 경우에는 해당 기간까지 보유합니다.

※ 고객님께서는 개인정보 제공에 동의하지 않으실 수 있으시며, 동의하지 않으신 경우 본 서비스를 이용하실 수 없습니다.
 
※ 회사는 상기 정보와 목적 외에는 가입자의 정보를 제공하지 않으며, 서비스 이용을 위한 목적 외에는 고객정보를 활용하지 않습니다.

(시행일) 본 약관은 2024년 06월 03일부터 시행합니다.`,
}

export const TERMS_LG_RECEIVE = {
  title: `LGU+ 및 LGU+ MVNO 개인정보 제3자 제공 동의`,
  desc: `㈜LG유플러스 및 ㈜LG유플러스의 망을 임대하여 이동통신 서비스를 제공하는 알뜰폰 사업자(MVNO)는 스마트피싱보호 서비스(ANTI-SCAM)(이하 '서비스')의 원활한 진행을 위해 다음과 같은 정보를 ㈜데이터유니버스에 제공합니다. <br/>
	<table class="terms_table">
		<tbody>
			<tr>
				<th>제공받는자</th>
				<td>㈜데이터유니버스</td>
			</tr>
			<tr>
				<th>제공목적</th>
				<td>서비스 가입 및 해지, 서비스 제공, 월정액 정산, 고객민원처리</td>
			</tr><tr>
				<th>제공하는 개인정보</th>
				<td>서비스 가입여부(가입,정지,해지), 고객상태(가입,정지,해지), 고객구분(개인/법인/개인사업자), 단말모델명, 선불폰여부, 알뜰폰여부, 가입계약번호, 미성년자여부</td>									
			</tr>                
			<tr>
				<th>제공받는자의 개인정보 보유기간</th>									
				<td>서비스 가입기간(가입일~해지일) 동안 이용하고, 요금정산, 요금 과오납 등 분쟁 대비를 위해 해지후 6개월까지 보유.(요금 미/과납이 있을 경우 해결시 까지 보유)</td>
			</tr>
		</tbody>
	</table>

	고객님께서는 개인정보 제공에 동의하지 않으실 수 있으시며, 동의하지 않으신 경우 본 서비스를 이용하실 수 없습니다.

	회사는 상기 정보와 목적 외에는 가입자의 정보를 제공하지 않으며, 서비스 이용을 위한 목적 외에는 고객정보를 활용하지 않습니다.

  (시행일) 본 약관은 2023년 9월 7일부터 시행합니다.`,
}
