import { useCallback, useState } from "react"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { format } from "date-fns"
import { useInput, useTextarea } from "hooks"
import { useVerifyQueryMutate } from "hooks/query"
import { deviceCheck, encodeData, layerToggle, validInputLength, validPhoneNumber } from "service/utils"
import { InsertDatepicker, InsertInput, InsertSelect, InsertTextarea } from "components/InsertForms"
import { SubmitButton } from "components/ContsButton"
import { CheckForms, CheckFormsList } from "components/CheckForms"
import { TermsPop } from "components/Popup"
import { TERMS_DAMAGE } from "service/constant/terms"
import { CMDTYPE_CASE_SHARE } from "service/constant"

const damageOptions = [
  { value: "1", name: "보이스피싱" },
  { value: "2", name: "스미싱" },
  { value: "3", name: "해킹" },
  { value: "4", name: "개인정보도용" },
  { value: "5", name: "기타 사기유형" },
]

const Share = () => {
  const [phone, onChangePhone, setPhone] = useInput("", true)
  const [date, setDate] = useState(new Date())
  const [doubt, onChangeDoubt, setDoubt] = useInput("", true)
  const [damageOpt, onChangeDamageOpt, setDamageOpt] = useInput("")
  const [damageDesc, onChangeDamageDesc, setDamageDesc] = useTextarea("", 1000)
  const [specific, onChangeSpecific, setSpecific] = useTextarea("", 300)
  const [termsAgree, setTermsAgree] = useState(0)
  const [showTerms, setShowTerms] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { mutate: shareVerify } = useVerifyQueryMutate()

  const resetShareData = useCallback(() => {
    setPhone("")
    setDoubt("")
    setDamageOpt("")
    setDamageDesc("")
    setSpecific("")
    setTermsAgree(0)
  }, [setPhone, setDoubt, setDamageOpt, setDamageDesc, setSpecific, setTermsAgree])
  const onChangeDate = useCallback((selectDate: Date) => {
    setDate(selectDate)
  }, [])
  const onChangeTermsAgree = useCallback(() => {
    setTermsAgree((prevTerms) => (prevTerms === 0 ? 1 : 0))
  }, [])
  const toggleTermsPop = useCallback(() => {
    layerToggle()
    setShowTerms((prevShow) => !prevShow)
  }, [])
  const isActiveShareButton = useCallback(() => {
    return validPhoneNumber(phone) &&
      !validInputLength(doubt, 10) &&
      damageOpt &&
      !validInputLength(damageDesc, 10) &&
      !validInputLength(specific, 10) &&
      termsAgree
      ? false
      : true
  }, [phone, doubt, damageOpt, damageDesc, specific, termsAgree])
  const shareVerifySubmit = useCallback(async () => {
    if (executeRecaptcha) {
      const token = await executeRecaptcha("login")

      shareVerify(
        {
          CmdType: CMDTYPE_CASE_SHARE,
          Body: {
            PNumber: phone,
            RecvPnumber: doubt,
            DamageType: damageOpt,
            CallTime: format(date, "yyyy-MM-dd"),
            Contents: encodeData(damageDesc),
            Uniqueness: encodeData(specific),
            AuthToken: token,
          },
        },
        {
          onSuccess: (data) => {
            if (data.Header.ErrCode !== 0) {
              alert(data.Header.ErrMsg)
            } else {
              alert("피해 접수 완료\n피해 사례를 공유해 주셔서 감사합니다.")
              resetShareData()
            }
          },
        },
      )
    }
  }, [phone, doubt, damageOpt, date, damageDesc, specific, executeRecaptcha, shareVerify, resetShareData])

  return (
    <>
      <h3 className="subconts_tit">피해사례공유</h3>
      <p>피싱사기 피해사례를 공유해주시면 또다른 피해자 발생을 막을 수 있습니다.</p>
      <section className="forms_wrap">
        <InsertInput
          title="등록자 전화번호"
          isValid={validPhoneNumber(phone)}
          type={deviceCheck() ? "tel" : "text"}
          maxLength={11}
          holder="본인의 연락처 (‘-’ 제외 숫자만 입력)"
          value={phone}
          changeFunc={onChangePhone}
        />
        <InsertDatepicker
          title="피해발생 일시"
          holder={`피해발생 당시의 날짜 예) ${format(new Date(), "yyyy-MM-dd")}`}
          value={date}
          changeFunc={onChangeDate}
        />
        <InsertInput
          title="의심 전화번호"
          isValid={!validInputLength(doubt, 10)}
          type={deviceCheck() ? "tel" : "text"}
          maxLength={15}
          holder="피해발생 당시 발신 번호(‘-’ 제외 숫자만 입력)"
          value={doubt}
          changeFunc={onChangeDoubt}
        />
        <InsertSelect
          title="피해유형"
          selectOptions={damageOptions}
          defaultValue={{ value: "", name: "피해 유형을 선택해 주세요." }}
          value={damageOpt}
          changeFunc={onChangeDamageOpt}
        />
        <InsertTextarea
          title="피해내용"
          limit={`${damageDesc.length}/1000`}
          isValid={!validInputLength(damageDesc, 10)}
          maxLength={1000}
          holder="정확한 사례 공유를 위해 최소 10자 이상 작성해주세요."
          value={damageDesc}
          changeFunc={onChangeDamageDesc}
        />
        <InsertTextarea
          title="특이사항"
          limit={`${specific.length}/300`}
          isValid={!validInputLength(specific, 10)}
          maxLength={300}
          holder="피해 내용 중 느꼈던 특이한 점을 작성해주세요."
          value={specific}
          changeFunc={onChangeSpecific}
        />
        <CheckFormsList>
          <CheckForms
            checkId="terms"
            text={"피해사례 공유 약관에 동의합니다. (피해사례공유 외의 용도로는 사용되지 않습니다.)"}
            value={termsAgree}
            changeFunc={onChangeTermsAgree}
            changeTerms={toggleTermsPop}
          />
        </CheckFormsList>
        <div className="cont_btns_wrap">
          <SubmitButton
            text="피해사례 공유하기"
            role="submit"
            isValid={isActiveShareButton()}
            clickFunc={shareVerifySubmit}
          />
        </div>
      </section>
      {showTerms && (
        <TermsPop termsObject={TERMS_DAMAGE} closeFunc={toggleTermsPop}>
          <SubmitButton text="확인" role="submit" clickFunc={toggleTermsPop} />
        </TermsPop>
      )}
    </>
  )
}

export default Share
