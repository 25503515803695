import styled from "styled-components"

export const LineTabWrap = styled.div`
  margin-bottom: 100px;
  @media all and (max-width: 1200px) {
    margin-bottom: 40px;
  }
  > ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const LineTabItem = styled.li`
  flex: 1;
  > a {
    display: block;
    width: 100%;
    padding: 20px 0;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    color: #a1aab9;
    border-bottom: 4px solid #a1aab9;
    @media all and (max-width: 1200px) {
      padding: 12px 0;
      font-size: 14px;
      border-bottom-width: 2px;
    }
    &.active {
      color: #272e40;
      border-color: #272e40;
    }
  }
`
