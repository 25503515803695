import { useMutation, useQueries, useQuery } from "react-query"
import {
  fetchCustomerData,
  fetchDefaultData,
  fetchFaqListData,
  fetchNewsData,
  fetchNoticeDetailData,
  fetchPhishingData,
  fetchPhishingDetailData,
} from "service/api/fetcher"
import {
  CMDTYPE_MAIN_HOMEPOP,
  CMDTYPE_FAQ,
  CMDTYPE_FAQ_DETAIL,
  CMDTYPE_MAIN_PHISHING,
  CMDTYPE_MAIN_PHISHING_DETAIL,
  CMDTYPE_NEWS,
  CMDTYPE_NEW_PHISHING,
  CMDTYPE_NOTICE,
  CMDTYPE_NOTICE_DETAIL,
  CMDTYPE_GET_TEMRS,
  CMDTYPE_CHECK_MVNO_MEMBER,
} from "service/constant"
import { PhishingElementType, VerifyQueryType } from "types"

export const useMainPopQueryData = () => {
  return useQuery([CMDTYPE_MAIN_HOMEPOP], () => fetchDefaultData(CMDTYPE_MAIN_HOMEPOP))
}

export const useNoticeQueryData = (length: number, more: number) => {
  return useQuery([CMDTYPE_NOTICE, more], () =>
    fetchCustomerData(CMDTYPE_NOTICE, { Length: length * (more + 1), Offset: 0 }),
  )
}

export const useNewsQueryData = (length: number, more: number) => {
  return useQuery([CMDTYPE_NEWS, more], () => fetchNewsData(CMDTYPE_NEWS, { Length: length * (more + 1), Offset: 0 }))
}

export const useNoticeDetailQueryData = (index: string | undefined) => {
  return useQuery([CMDTYPE_NOTICE_DETAIL, index], () => fetchNoticeDetailData(CMDTYPE_NOTICE_DETAIL, { Idx: index }))
}

export const useFaqQueryData = () => {
  return useQuery(CMDTYPE_FAQ, () => fetchCustomerData(CMDTYPE_FAQ, { Length: 5, Offset: 0 }))
}

export const useFaqListQueryData = (type: string, faqIndex: number) => {
  return useQuery([CMDTYPE_FAQ_DETAIL, faqIndex], () =>
    fetchFaqListData(CMDTYPE_FAQ_DETAIL, {
      Type: type,
      Length: 9999,
      Offset: 0,
    }),
  )
}

export const usePhishingQueryData = (cmdType: string, paramId?: string) => {
  return useQuery(
    cmdType,
    () =>
      fetchPhishingData(cmdType, {
        PCode: "Datauniverse",
        Month: new Date().getMonth() + 1,
        Length: 12,
        Offset: 0,
      }),
    {
      select: (data) => {
        if (paramId) {
          const newData = data.Body.filter((item: PhishingElementType) => {
            return item.Idx === paramId && item
          })[0]
          const recentData = data.Body.filter((item: PhishingElementType) => {
            return item.Idx !== paramId && item
          }).splice(0, 3)
          return {
            ...data,
            newData,
            recentData,
          }
        }
        return data
      },
    },
  )
}

export const usePhishingListQueryData = () => {
  const reqBody = {
    PCode: "Datauniverse",
    Month: new Date().getMonth() + 1,
    Length: 12,
    Offset: 0,
  }
  return useQueries([
    {
      queryKey: CMDTYPE_NEW_PHISHING,
      queryFn: () => fetchPhishingData(CMDTYPE_NEW_PHISHING, reqBody),
    },
    {
      queryKey: CMDTYPE_MAIN_PHISHING,
      queryFn: () => fetchPhishingData(CMDTYPE_MAIN_PHISHING, reqBody),
    },
  ])
}

export const useGetCaptchaQueryMutate = () => {
  return useMutation((CmdType: string) => fetchDefaultData(CmdType))
}

export const useVerifyQueryMutate = () => {
  return useMutation(({ CmdType, Body }: { CmdType: string; Body: VerifyQueryType }) => fetchDefaultData(CmdType, Body))
}

export const useMainPhishingDetailQueryMutate = () => {
  return useMutation((paramIdx: string | undefined) =>
    fetchPhishingDetailData(CMDTYPE_MAIN_PHISHING_DETAIL, {
      PCode: "Datauniverse",
      Idx: paramIdx,
    }),
  )
}

export const useTermsData = () => {
  return useQuery([CMDTYPE_GET_TEMRS], () => fetchDefaultData(CMDTYPE_GET_TEMRS))
}

export const useCheckMvnoMember = () => {
  return useMutation((params: { TeleType: string; PNumber: string }) =>
    fetchDefaultData(CMDTYPE_CHECK_MVNO_MEMBER, { ...params }),
  )
}
