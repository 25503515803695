import { useCallback, useState } from "react"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { useInput, useTextarea } from "hooks"
import { useVerifyQueryMutate } from "hooks/query"
import { deviceCheck, encodeData, layerToggle, validEmail, validInputLength, validPhoneNumber } from "service/utils"
import { CMDTYPE_QNA_REGIST } from "service/constant"
import { TERMS_PERSONAL } from "service/constant/terms"
import { SubmitButton } from "components/ContsButton"
import { InsertInput, InsertSelect, InsertTextarea } from "components/InsertForms"
import { CheckForms, CheckFormsList } from "components/CheckForms"
import { TermsPop } from "components/Popup"

const inquiryOptions = [
  { value: "1", name: "서비스 가입" },
  { value: "2", name: "서비스 해지" },
  { value: "3", name: "이용문의" },
  { value: "4", name: "제휴문의" },
  { value: "5", name: "기타" },
]

const Inquiry = () => {
  const [inquiryOpt, onChangeInquiryOpt, setInquiryOpt] = useInput("")
  const [name, onChangeName, setName] = useInput("")
  const [phone, onChangePhone, setPhone] = useInput("", true)
  const [email, onChangeEmail, setEmail] = useInput("")
  const [title, onChangeTitle, setTitle] = useInput("")
  const [desc, onChangeDesc, setDesc] = useTextarea("", 150)
  const [termsAgree, setTermsAgree] = useState(0)
  const [showTerms, setShowTerms] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { mutate: inquiryVerify } = useVerifyQueryMutate()

  const resetInquiryData = useCallback(() => {
    setInquiryOpt("")
    setName("")
    setPhone("")
    setEmail("")
    setTitle("")
    setDesc("")
    setTermsAgree(0)
  }, [setInquiryOpt, setName, setPhone, setEmail, setTitle, setDesc, setTermsAgree])
  const onChangeTermsAgree = useCallback(() => {
    setTermsAgree((prevTerms) => (prevTerms === 0 ? 1 : 0))
  }, [])
  const toggleTermsPop = useCallback(() => {
    layerToggle()
    setShowTerms((prevShow) => !prevShow)
  }, [])
  const isActiveInquiryButton = useCallback(() => {
    return inquiryOpt &&
      !validInputLength(name, 2) &&
      validPhoneNumber(phone) &&
      validEmail(email) &&
      !validInputLength(title, 2) &&
      !validInputLength(desc, 10) &&
      termsAgree
      ? false
      : true
  }, [inquiryOpt, name, phone, email, title, desc, termsAgree])
  const inquiryVerifySubmit = useCallback(async () => {
    if (executeRecaptcha) {
      const token = await executeRecaptcha("login")

      inquiryVerify(
        {
          CmdType: CMDTYPE_QNA_REGIST,
          Body: {
            Type: inquiryOpt,
            PName: name,
            PNumber: phone,
            Email: email,
            Title: encodeData(title),
            Contents: encodeData(desc),
            AuthToken: token,
          },
        },
        {
          onSuccess: (data) => {
            if (data.Header.ErrCode !== 0) {
              alert(data.Header.ErrMsg)
            } else {
              alert("문의가 정상적으로 접수됐습니다.")
              resetInquiryData()
            }
          },
        },
      )
    }
  }, [inquiryOpt, name, phone, email, title, desc, executeRecaptcha, inquiryVerify, resetInquiryData])

  return (
    <>
      <h3 className="subconts_tit">1:1문의</h3>
      <section className="forms_wrap">
        <InsertSelect
          title="문의유형"
          selectOptions={inquiryOptions}
          defaultValue={{ value: "", name: "유형을 선택해 주세요." }}
          value={inquiryOpt}
          changeFunc={onChangeInquiryOpt}
        />
        <InsertInput
          title="성명"
          isValid={!validInputLength(name, 2)}
          type="text"
          maxLength={10}
          holder="이름을 입력해주세요."
          value={name}
          changeFunc={onChangeName}
        />
        <InsertInput
          title="연락처"
          isValid={validPhoneNumber(phone)}
          type={deviceCheck() ? "tel" : "text"}
          maxLength={11}
          holder="‘-’ 제외 하고 입력해 주세요."
          value={phone}
          changeFunc={onChangePhone}
        />
        <InsertInput
          title="이메일"
          isValid={validEmail(email)}
          type="email"
          maxLength={30}
          holder="이메일 주소를 입력해주세요."
          value={email}
          changeFunc={onChangeEmail}
        />
        <InsertInput
          title="제목"
          isValid={!validInputLength(title, 2)}
          type="text"
          maxLength={50}
          holder="제목을 최소 2자 이상 입력해주세요."
          value={title}
          changeFunc={onChangeTitle}
        />
        <InsertTextarea
          title="문의내용"
          limit={`${desc.length}/150`}
          isValid={!validInputLength(desc, 10)}
          maxLength={150}
          holder="문의 내용을 최소 10자 이상 입력해주세요."
          value={desc}
          changeFunc={onChangeDesc}
        />
        <CheckFormsList>
          <CheckForms
            checkId="terms"
            text={"개인정보 수집 및 이용에 동의합니다.  (문의 응대 외의 용도로는 사용되지 않습니다.)"}
            value={termsAgree}
            changeFunc={onChangeTermsAgree}
            changeTerms={toggleTermsPop}
          />
        </CheckFormsList>
        <div className="cont_btns_wrap">
          <SubmitButton
            text="문의하기"
            role="submit"
            isValid={isActiveInquiryButton()}
            clickFunc={inquiryVerifySubmit}
          />
        </div>
      </section>
      {showTerms && (
        <TermsPop termsObject={TERMS_PERSONAL} closeFunc={toggleTermsPop}>
          <SubmitButton text="확인" role="submit" clickFunc={toggleTermsPop} />
        </TermsPop>
      )}
    </>
  )
}

export default Inquiry
