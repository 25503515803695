import { ReactNode, ChangeEvent, KeyboardEvent, RefObject, forwardRef, HTMLProps, Ref } from "react"
import DatePicker from "react-datepicker"
import ko from "date-fns/locale/ko"
import IndexButtonsList from "components/IndexButtonsList"
import { InsertBox, InsertInner, InsertTitle, InsertWrap } from "./styles"

export interface InsertDefaultType {
  title?: string
  isValid?: boolean
}

export interface InsertFormsType extends InsertDefaultType {
  children?: ReactNode
}

export interface InsertTelType extends InsertDefaultType {
  telList: {
    idx: number
    text: string
  }[]
  telIdx: number
  clickFunc: (tabIndex: number) => void
}

export const InsertTel = ({ title, telList, telIdx, isValid, clickFunc }: InsertTelType) => {
  return (
    <InsertWrap>
      <InsertTitle>
        <strong className="title">{title}</strong>
      </InsertTitle>
      <IndexButtonsList indexList={telList} nowIndex={telIdx} isValid={isValid} changeIndex={clickFunc} />
    </InsertWrap>
  )
}

export interface InsertInputType extends InsertFormsType {
  type: string
  maxLength?: number
  holder: string
  readTo?: boolean
  Inputref?: RefObject<HTMLInputElement>
  value: string | number
  changeFunc: (e: ChangeEvent<HTMLInputElement>) => void
  keyboardFunc?: () => void
}

export const InsertInput = ({
  children,
  title,
  isValid,
  type,
  maxLength,
  holder,
  readTo,
  Inputref,
  value,
  changeFunc,
  keyboardFunc,
}: InsertInputType) => {
  const keyPressEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (isValid && keyboardFunc) {
      if (e.key === "Enter") keyboardFunc()
    }
  }

  return (
    <InsertWrap>
      {title && (
        <InsertTitle isValid={isValid}>
          <strong className="title">{title}</strong>
        </InsertTitle>
      )}
      <InsertBox>
        <InsertInner isValid={isValid}>
          <input
            type={type}
            maxLength={maxLength}
            placeholder={holder}
            readOnly={readTo}
            ref={Inputref}
            value={value}
            onChange={changeFunc}
            onKeyPress={keyPressEnter}
          />
          <i className="is_valid"></i>
        </InsertInner>
        {children}
      </InsertBox>
    </InsertWrap>
  )
}

export interface InsertSelectType extends InsertDefaultType {
  selectOptions: {
    value: string
    name: string
  }[]
  defaultValue: {
    value: string
    name: string
  }
  value: string
  changeFunc: (e: ChangeEvent<HTMLSelectElement>) => void
}

export const InsertSelect = ({ title, selectOptions, defaultValue, value, changeFunc }: InsertSelectType) => {
  return (
    <InsertWrap>
      {title && (
        <InsertTitle>
          <strong className="title">{title}</strong>
        </InsertTitle>
      )}
      <InsertBox>
        <InsertInner isSelect={true}>
          <select value={value} onChange={changeFunc}>
            <option value={defaultValue.value} defaultValue={defaultValue.value} disabled>
              {defaultValue.name}
            </option>
            {selectOptions.map((option) => {
              return (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              )
            })}
          </select>
        </InsertInner>
      </InsertBox>
    </InsertWrap>
  )
}

export interface InsertTextareaType extends InsertFormsType {
  limit?: string
  maxLength?: number
  holder: string
  value: string
  changeFunc: (e: ChangeEvent<HTMLTextAreaElement>) => void
}

export const InsertTextarea = ({ title, limit, isValid, maxLength, holder, value, changeFunc }: InsertTextareaType) => {
  return (
    <InsertWrap>
      <InsertTitle isValid={isValid}>
        <strong className="title">{title}</strong>
        <span className="limit">{limit}</span>
      </InsertTitle>
      <InsertBox>
        <InsertInner>
          <textarea maxLength={maxLength} placeholder={holder} value={value} onChange={changeFunc} />
        </InsertInner>
      </InsertBox>
    </InsertWrap>
  )
}

export interface InsertDatepickerType extends InsertFormsType {
  holder: string
  value: Date
  changeFunc: (date: Date) => void
}

export const InsertDatepicker = ({ title, holder, value, changeFunc }: InsertDatepickerType) => {
  const PickerButton = forwardRef((props: HTMLProps<HTMLButtonElement>, ref: Ref<HTMLButtonElement>) => {
    const { value, onClick } = props

    return (
      <button type="button" className="picker_button" onClick={onClick} ref={ref}>
        <i className="icon_calendar">📆</i>
        {value}
      </button>
    )
  })

  return (
    <InsertWrap>
      {title && (
        <InsertTitle>
          <strong className="title">{title}</strong>
        </InsertTitle>
      )}
      <InsertBox>
        <InsertInner>
          <DatePicker
            locale={ko}
            showPopperArrow={false}
            dateFormat={"yyyy-MM-dd"}
            maxDate={new Date()}
            placeholderText={holder}
            selected={value}
            onChange={changeFunc}
            customInput={<PickerButton />}
          />
        </InsertInner>
      </InsertBox>
    </InsertWrap>
  )
}
