import { KAKAO_DEV_KEY, OWRS_DEV, OWRS_LIVE } from "service/constant"
import { PhishingElementType } from "types"

export const deviceCheck = () => {
  const filter = "win|win32|win64|mac|macintel"
  if (navigator.platform) return filter.indexOf(navigator.platform.toLowerCase()) < 0 ? true : false // mobile : pc
}

export const webServer = () => {
  if (window.location.href.indexOf("localhost") > -1) return "http://localhost:3000"
  else if (window.location.href.indexOf("dev") > -1) return "https://dev-www.antiscam.co.kr"
  else return "https://www.antiscam.co.kr"
}

export const hostServer = () => {
  if (window.location.href.indexOf("localhost") > -1 || window.location.href.indexOf("dev") > -1) return OWRS_DEV
  return OWRS_LIVE
}

export const httpData = <T>(CmdType: string, Body?: T) => {
  return JSON.stringify({
    Header: {
      CmdType,
    },
    Body,
  })
}

export const validPhoneNumber = (num: string) => {
  const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/
  return regPhone.test(num)
}

export const validEmail = (email: string) => {
  const regEmail = /^[0-9a-zA-Z]([-_\\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/
  return regEmail.test(email)
}

export const validInputLength = (inputValue: string, min: number) => {
  return inputValue.length < min ? true : false
}

export const layerOpen = () => {
  document.querySelector("body")?.classList.add("layer_opened")
}

export const layerClose = () => {
  document.querySelector("body")?.classList.remove("layer_opened")
}

export const layerToggle = () => {
  document.querySelector("body")?.classList.toggle("layer_opened")
}

export const padTime = (time: number) => (time < 10 ? "0" : "") + time

export const encodeData = (element: string) => {
  return window.btoa(unescape(encodeURIComponent(element)))
}

export const decodeData = (element: string) => {
  return decodeURIComponent(escape(window.atob(element)))
}

export const kakaoInit = () => {
  window.Kakao.init(KAKAO_DEV_KEY)
}

export const kakaoSendShare = (detailData: PhishingElementType, casePhishing: "new" | "main") => {
  window.Kakao.Link.sendDefault({
    objectType: "feed",
    content: {
      title: detailData.Title,
      imageHeight: 550,
      imageUrl: casePhishing === "new" ? detailData.serviceImg : detailData.CardThumbNail,
      link: {
        webUrl: `${webServer()}/phishing/${casePhishing}/${detailData.Idx}`,
        mobileWebUrl: `${webServer()}/phishing/${casePhishing}/${detailData.Idx}`,
      },
    },
    buttons: [
      {
        title: "자세히 보기",
        link: {
          webUrl: `${webServer()}/phishing/${casePhishing}/${detailData.Idx}`,
          mobileWebUrl: `${webServer()}/phishing/${casePhishing}/${detailData.Idx}`,
        },
      },
    ],
  })
}

export const copyURL = (mainId: string | undefined) => {
  const isNewDetail = window.document.location.href.indexOf("/new") > -1 ? true : false
  const href = isNewDetail ? window.document.location.href : window.document.location.href.split("/main")[0]
  const copyEle = document.createElement("input")
  document.body.appendChild(copyEle)
  copyEle.value = isNewDetail ? href : `${href}/main/${mainId}`
  copyEle.select()
  document.execCommand("copy")
  document.body.removeChild(copyEle)
  alert("주소가 복사됐습니다.\n원하는 곳에 붙여넣기(Ctrl+V)하세요.")
}

export const checkDeviceWidth = (width: number) => (width > 1200 ? true : false)

export const onScrollTop = (behavior: boolean) => {
  const body = document.querySelector("body")
  body?.scrollTo({
    top: 0,
    behavior: behavior ? "smooth" : "auto",
  })
}

export const mainScrollPostion = (eleName: string, eleMargin: number) => {
  const observer = new IntersectionObserver(observerCallback, {
    rootMargin: eleMargin + "px",
    threshold: 0.5,
  })
  function observerCallback(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (entry.isIntersecting) entry.target.classList.add("active")
    })
  }
  document.querySelectorAll(eleName).forEach((eleItem) => {
    if (eleItem) observer.observe(eleItem)
  })
}

export const pauseTodayPop = () => {
  const pauseCookie = localStorage.getItem("pauseToday")
  const currentTime = Math.floor(new Date().getTime())
  if (!pauseCookie) return true
  if (Number(pauseCookie) < currentTime) return true
  return false
}
