import { useCallback, useEffect, useRef, useState, ChangeEvent } from "react"
import { useNavigate } from "react-router-dom"
import { useIndexButton, useTimer, useInput } from "hooks"
import { useGetCaptchaQueryMutate, useVerifyQueryMutate, useCheckMvnoMember } from "hooks/query"
import { deviceCheck, layerOpen, layerToggle, validInputLength, validPhoneNumber, decodeData } from "service/utils"
import { CMDTYPE_GET_CAPTCHA, CMDTYPE_SEND_VERIFY, CMDTYPE_SEND_CAPTCHA } from "service/constant"
import {
  TERMS_INSURANCE,
  TERMS_KT_RECVEIVE,
  TERMS_MARKETING,
  TERMS_PERSONAL_TEL,
  TERMS_RECEIVE_OTHER,
  TERMS_USED_KT_PAY,
  TERMS_USED_LG_PAY,
  TERMS_USED_SKT,
  TERMS_RECEIVE_OTHER_LG,
  TERMS_LG_RECEIVE,
} from "service/constant/terms"
import { TermsDocType } from "types"
import SubpageTop from "layouts/SubpageTop"
import SubpageConts from "layouts/SubpageConts"
import { SubmitButton } from "components/ContsButton"
import { CaptchaPopup, InfoPopup, LoadingPop, TermsPop, MVNOPopup } from "components/Popup"
import { InsertTel, InsertInput } from "components/InsertForms"
import { CheckForms, CheckFormsList } from "components/CheckForms"

const telIndexList = [
  { idx: 0, text: "SKT" },
  { idx: 1, text: "KT" },
  { idx: 2, text: "LG U+" },
]
const sktTermsList = [
  { idx: 0, isCheck: 0, text: "스마트피싱보호 서비스 이용약관(필수)", doc: TERMS_USED_SKT },
  { idx: 1, isCheck: 0, text: "개인정보 수집 및 이용 동의(필수)", doc: TERMS_PERSONAL_TEL },
  { idx: 2, isCheck: 0, text: "마케팅정보 수신 동의(선택)", doc: TERMS_MARKETING },
]
const ktTermsList = [
  { idx: 0, isCheck: 0, text: "스마트피싱보호 서비스 이용약관(필수)", doc: TERMS_USED_KT_PAY },
  { idx: 1, isCheck: 0, text: "개인정보 수집 및 이용 동의(필수)", doc: TERMS_PERSONAL_TEL },
  { idx: 2, isCheck: 0, text: "KT 개인정보의 제3자 제공동의(필수)", doc: TERMS_KT_RECVEIVE },
  { idx: 3, isCheck: 0, text: "개인정보의 제3자 제공동의(필수)", doc: TERMS_RECEIVE_OTHER },
  { idx: 4, isCheck: 0, text: "보험무료동의(선택)", doc: TERMS_INSURANCE },
  { idx: 5, isCheck: 0, text: "마케팅정보 수신 동의(선택)", doc: TERMS_MARKETING },
]
const lguTermsList = [
  { idx: 0, isCheck: 0, text: "스마트피싱보호 서비스 이용약관(필수)", doc: TERMS_USED_LG_PAY },
  { idx: 1, isCheck: 0, text: "개인정보 수집 및 이용 동의(필수)", doc: TERMS_PERSONAL_TEL },
  { idx: 2, isCheck: 0, text: "LGU+ 및 LGU+ MVNO 개인정보 제3자 제공 동의(필수)", doc: TERMS_LG_RECEIVE },
  { idx: 3, isCheck: 0, text: "개인정보의 제3자 제공동의(필수)", doc: TERMS_RECEIVE_OTHER_LG },
  { idx: 4, isCheck: 0, text: "마케팅정보 수신 동의(선택)", doc: TERMS_MARKETING },
]
const popupText = {
  3: "인증번호 3회 오류<br />인증번호를 다시 받아주세요.",
  7: "인증번호가 성공적으로 전송됐습니다.",
  8: "인증번호를 입력해 주세요.",
  10: "필수 약관에 동의 해주세요.",
  11: "인증번호 받기를 먼저 시도해 주세요.",
  12: "유효 인증시간이 초과되었습니다.<br />인증번호를 다시 받아주세요.",
  13: "가입이 완료됐습니다.<br/>전송된 문자를 통해 앱을 설치하세요.",
  16: "휴대폰 번호 또는 통신사를 확인하세요.",
  // 2024-08-07 LG U+ 가입완료 문구 분리
  18: "스마트피싱보호 안내<br />휴대폰을 통해 발생하는 보이스피싱, 스미싱 등 금융 사기 피해로부터 안전하게 보호 받을 수 있는 안심 유료부가서비스에 가입되셨습니다. 원활한 이용을 위해 [확인] 버튼을 눌러 앱스토어로 이동하세요.",
} as const

type popupTextType = keyof typeof popupText
const Join = () => {
  const navigate = useNavigate()
  const [popIdx, onChangePopIdx] = useIndexButton(0)
  const [telIndex, onChangeTelIndex] = useIndexButton(0)
  const [phone, onChangePhone, setPhone] = useInput("", true)
  const [captchaNumber, onChangeCaptchaNumber, setCaptchaNumber] = useInput("", true)
  const [termsAll, setTermsAll] = useState(0)
  const [termsEach, setTermsEach] = useState(sktTermsList)
  const [termsDoc, setTermsDoc] = useState<TermsDocType | null>(null)
  const [cert, onChangeCert, setCert] = useInput("", true)
  const [min, sec, time, timeRef, startTimer, resetTimer] = useTimer()
  const phoneInput = useRef<HTMLInputElement>(null)
  const certInput = useRef<HTMLInputElement>(null)
  const { data: captchaData, mutate: getCaptcha, reset: resetCaptcha } = useGetCaptchaQueryMutate()
  const { data: sendCaptchaData, mutate: sendCaptcha, reset: resetSendCaptcha } = useVerifyQueryMutate()
  const { isLoading, data: joinVerifyData, mutate: joinVerify, reset: resetJoinVerify } = useVerifyQueryMutate()
  const certButtonRef = useRef<HTMLButtonElement>(null)
  const joinButtonRef = useRef<HTMLButtonElement>(null)
  const captchaButtonRef = useRef<HTMLButtonElement>(null)
  const capchaInputRef = useRef<HTMLInputElement>(null)

  const { data: mvnoMemberData, mutateAsync: getMvnoMemberCheck } = useCheckMvnoMember()
  const [isMvnoConfirm, setIsMvnoConfirm] = useState<boolean>(false)

  const resetPopIdx = useCallback(() => {
    layerToggle()
    onChangePopIdx(0)
    if (popIdx === 13 || popIdx === 18) navigate("/")
  }, [popIdx, onChangePopIdx, navigate])
  const toggleTermsPop = useCallback(
    (termsIdx?: number) => {
      if (typeof termsIdx === "number") {
        if (telIndex === 0) setTermsDoc(sktTermsList[termsIdx as number].doc)
        if (telIndex === 1) setTermsDoc(ktTermsList[termsIdx as number].doc)
        if (telIndex === 2) setTermsDoc(lguTermsList[termsIdx as number].doc)
      } else {
        setTermsDoc(null)
      }
      layerToggle()
    },
    [telIndex],
  )
  const onChangeTelIndexToTerms = useCallback(
    (telIndex: number) => {
      if (telIndex === 0) setTermsEach(sktTermsList)
      if (telIndex === 1) setTermsEach(ktTermsList)
      if (telIndex === 2) setTermsEach(lguTermsList)
      onChangeTelIndex(telIndex)
      setTermsAll(0)
    },
    [onChangeTelIndex],
  )
  const onChangeTermsAll = useCallback(() => {
    if (!termsAll) {
      setTermsAll(1)
      if (telIndex === 0) {
        const newTermsEach = termsEach.map((terms) => {
          return terms.idx === 2 ? terms : { ...terms, isCheck: 1 }
        })
        setTermsEach(newTermsEach)
      }
      if (telIndex === 1) {
        const newTermsEach = termsEach.map((terms) => {
          return terms.idx === 4 || terms.idx === 5 ? terms : { ...terms, isCheck: 1 }
        })
        setTermsEach(newTermsEach)
      }
      if (telIndex === 2) {
        const newTermsEach = termsEach.map((terms) => {
          return terms.idx === 4 ? terms : { ...terms, isCheck: 1 }
        })
        setTermsEach(newTermsEach)
      }
    } else {
      setTermsAll(0)
      const newTermsEach = termsEach.map((terms) => {
        return { ...terms, isCheck: 0 }
      })
      setTermsEach(newTermsEach)
    }
  }, [telIndex, termsAll, termsEach])
  const onChangeTermsEach = useCallback(
    (termsIdx: number) => {
      const newTermsEach = termsEach.map((terms) => {
        return terms.idx !== termsIdx ? terms : { ...terms, isCheck: terms.isCheck === 0 ? 1 : 0 }
      })
      setTermsEach(newTermsEach)
      if (telIndex !== 0) {
        if (newTermsEach[0].isCheck && newTermsEach[1].isCheck && newTermsEach[2].isCheck && newTermsEach[3].isCheck) {
          setTermsAll(1)
        } else setTermsAll(0)
      } else {
        if (newTermsEach[0].isCheck && newTermsEach[1].isCheck) setTermsAll(1)
        else setTermsAll(0)
      }
    },
    [telIndex, termsEach],
  )
  const resetCallback = useCallback(() => {
    onChangeTelIndex(0)
    setPhone("")
    setCaptchaNumber("")
    setCert("")
    setTermsEach(sktTermsList)
    setTermsAll(0)
    resetCaptcha()
    resetSendCaptcha()
    resetJoinVerify()
    setIsMvnoConfirm(false)
  }, [
    onChangeTelIndex,
    setPhone,
    setCaptchaNumber,
    setCert,
    setTermsAll,
    setTermsEach,
    resetCaptcha,
    resetSendCaptcha,
    resetJoinVerify,
  ])
  const recaptchaSend = useCallback(() => {
    const phoneEle = phoneInput.current
    const buttonEle = certButtonRef.current
    if (phoneEle) phoneEle.blur()
    if (buttonEle) buttonEle.blur()
    getCaptcha(CMDTYPE_GET_CAPTCHA, {
      onSuccess: () => {
        setCaptchaNumber("")
        layerOpen()
        onChangePopIdx(4)
      },
    })
  }, [getCaptcha, onChangePopIdx, setCaptchaNumber])
  const onCaptchaSend = useCallback(
    (resendFlag?: boolean) => {
      const phoneEle = phoneInput.current
      const buttonEle = certButtonRef.current
      if (phoneEle) phoneEle.blur()
      if (buttonEle) buttonEle.blur()

      // mvno check api
      if (!resendFlag && telIndex === 1) {
        getMvnoMemberCheck({ TeleType: `${telIndex}`, PNumber: phone }).then((res) => {
          setIsMvnoConfirm(false)
          const { Type } = res.Body
          if (Type === 1) {
            layerOpen()
            onChangePopIdx(14)
          } else {
            recaptchaSend()
          }
        })
      } else {
        recaptchaSend()
      }
    },
    [getMvnoMemberCheck, onChangePopIdx, phone, recaptchaSend, telIndex],
  )
  const onCaptchaSubmit = useCallback(() => {
    const inputEle = capchaInputRef?.current
    const buttonEle = captchaButtonRef?.current
    if (buttonEle) buttonEle.blur()
    if (inputEle) inputEle.blur()
    if (captchaNumber.length < 4) {
      alert("보안문자 입력을 확인해주세요.")
      return false
    }

    sendCaptcha(
      {
        CmdType: CMDTYPE_SEND_CAPTCHA,
        Body: {
          CaptchaTransID: captchaData.Body.TransID,
          TeleType: String(telIndex),
          PNumber: phone,
          AuthType: "Add",
          AuthToken: captchaNumber,
          Pcode: "ec9a7230-7725",
          CALLTYPE: deviceCheck() ? "MOBILEWEB" : "PCWEB",
        },
      },
      {
        onSuccess: (data) => {
          if (data.Header.ErrCode !== 0) {
            alert(data.Header.ErrMsg)
            setCaptchaNumber("")
            switch (data.Header.ErrCode) {
              case 100305:
                resetPopIdx()
                if (data.Body.URL) window.location.href = data.Body.URL
                break
              case 100401:
                resetPopIdx()
                break
              case 100403:
              case 100503:
              case 100504:
              case 900002:
              case 100405:
                resetTimer(resetCallback)
                resetPopIdx()
                break
            }
          } else {
            alert("인증번호가 성공적으로 전송됐습니다.")
            resetTimer()
            resetPopIdx()
            startTimer(data.Body.TimeOut)
            const certEle = certInput.current
            if (certEle) certEle.focus()
          }
        },
      },
    )
  }, [
    telIndex,
    phone,
    captchaNumber,
    captchaData,
    sendCaptcha,
    setCaptchaNumber,
    resetCallback,
    resetPopIdx,
    resetTimer,
    startTimer,
  ])
  const joinVerifySubmit = useCallback(() => {
    const certEle = certInput.current
    const joinButtonEle = joinButtonRef.current
    if (certEle) certEle.blur()
    if (joinButtonEle) joinButtonEle.blur()
    if (!sendCaptchaData?.Body.TransactionID) {
      layerToggle()
      onChangePopIdx(11)
      return false
    }
    if (phone.length < 10) {
      layerToggle()
      onChangePopIdx(16)
      return false
    }
    if (cert.length < 4) {
      layerToggle()
      onChangePopIdx(8)
      return false
    }
    if (!termsAll) {
      if (certEle) certEle.blur()
      layerToggle()
      onChangePopIdx(10)
      return false
    }
    let body
    switch (telIndex) {
      case 1:
        body = {
          TeleType: String(telIndex),
          PNumber: phone,
          AuthType: "Add",
          Pcode: "ec9a7230-7725",
          TransactionID: sendCaptchaData?.Body.TransactionID,
          CryptoNumber: cert,
          CALLTYPE: deviceCheck() ? "MOBILEWEB" : "PCWEB",
          Terms1Agree: String(termsEach[0]?.isCheck),
          Terms2Agree: String(termsEach[1]?.isCheck),
          Terms3Agree: String(termsEach[2]?.isCheck),
          Terms4Agree: String(termsEach[3]?.isCheck),
          Terms5Agree: String(termsEach[5]?.isCheck),
          Terms6Agree: String(termsEach[4]?.isCheck),
          Terms7Agree: isMvnoConfirm ? "1" : "0",
        }
        break
      case 2:
        body = {
          TeleType: String(telIndex),
          PNumber: phone,
          AuthType: "Add",
          Pcode: "ec9a7230-7725",
          TransactionID: sendCaptchaData?.Body.TransactionID,
          CryptoNumber: cert,
          CALLTYPE: deviceCheck() ? "MOBILEWEB" : "PCWEB",
          Terms1Agree: String(termsEach[0]?.isCheck),
          Terms2Agree: String(termsEach[1]?.isCheck),
          Terms3Agree: String(termsEach[2]?.isCheck),
          Terms4Agree: String(termsEach[3]?.isCheck),
          Terms5Agree: String(termsEach[4]?.isCheck),
          Terms6Agree: "0",
        }
        break
      default:
        body = {
          TeleType: String(telIndex),
          PNumber: phone,
          AuthType: "Add",
          Pcode: "ec9a7230-7725",
          TransactionID: sendCaptchaData?.Body.TransactionID,
          CryptoNumber: cert,
          CALLTYPE: deviceCheck() ? "MOBILEWEB" : "PCWEB",
          Terms1Agree: String(termsEach[0]?.isCheck),
          Terms2Agree: String(termsEach[1]?.isCheck),
          Terms3Agree: "0",
          Terms4Agree: "0",
          Terms5Agree: String(termsEach[2]?.isCheck),
          Terms6Agree: "0",
        }
    }
    joinVerify(
      {
        CmdType: CMDTYPE_SEND_VERIFY,
        Body: body,
      },
      {
        onSuccess: (data) => {
          if (data.Header.ErrCode !== 0) {
            if (data.Header.ErrCode === 100503) {
              onChangePopIdx(3)
              resetTimer(resetCallback)
            } else {
              onChangePopIdx(9)
            }
            setCert("")
          } else {
            resetTimer(resetCallback)
            onChangePopIdx(telIndex !== 2 ? 13 : 18)
          }
        },
      },
    )
  }, [
    sendCaptchaData,
    telIndex,
    phone,
    cert,
    termsAll,
    termsEach,
    onChangePopIdx,
    joinVerify,
    setCert,
    resetTimer,
    resetCallback,
    isMvnoConfirm,
  ])

  useEffect(() => {
    if (time.current < 0) {
      layerToggle()
      onChangePopIdx(12)
      resetTimer(resetCallback)
    }
  }, [sec, time, onChangePopIdx, resetTimer, resetCallback])
  useEffect(() => {
    let timeRefValue = 0
    if (timeRef.current) timeRefValue = timeRef.current
    return () => {
      clearInterval(timeRefValue)
    }
  }, [timeRef])

  // KT MVNO 약관동의 팝업 추가
  const mvnoCheck = () => {
    if (!isMvnoConfirm) {
      if (window.confirm("서비스 이용을 위하여 약관동의가 필요합니다. 모두 동의하시겠습니까?")) {
        setIsMvnoConfirm(true)
        recaptchaSend()
      }
      return
    }

    setIsMvnoConfirm(true)
    recaptchaSend()
  }
  return (
    <>
      {isLoading &&
        (function () {
          layerOpen()
          return <LoadingPop />
        })()}
      <SubpageTop />
      <SubpageConts>
        <div className="subpage_info">
          <strong className="title">스마트피싱보호는 피싱방지 서비스입니다.</strong>
          <pre>
            [스마트피싱보호]는 피싱사기 피해방지를 위하여 빅데이터 분석으로 예측된 최신/주요피싱정보를 제공하고
            피싱의심전화가 걸려오면 단계별 알림을 보내 나와 소중한 우리 가족을 피싱사기 피해로 부터 보호할 수 있는
            서비스입니다.
          </pre>
        </div>
        <h3 className="subconts_tit">서비스 가입</h3>
        <section className="forms_wrap">
          <InsertTel
            title="통신사 선택"
            telList={telIndexList}
            telIdx={telIndex}
            isValid={sendCaptchaData?.Body.TransactionID ? true : false}
            clickFunc={onChangeTelIndexToTerms}
          />
          <InsertInput
            title="휴대폰 번호"
            isValid={validPhoneNumber(phone)}
            type={deviceCheck() ? "tel" : "text"}
            maxLength={11}
            holder="‘-’ 제외 하고 입력해 주세요."
            readTo={sendCaptchaData?.Body.TransactionID ? true : false}
            Inputref={phoneInput}
            value={phone}
            changeFunc={(e: ChangeEvent<HTMLInputElement>) => {
              onChangePhone(e)
              setIsMvnoConfirm(false)
            }}
            keyboardFunc={() => onCaptchaSend()}
          >
            <SubmitButton
              buttonRef={certButtonRef}
              text="인증번호 받기"
              role="submit"
              isValid={!validPhoneNumber(phone)}
              clickFunc={() => onCaptchaSend()}
            />
          </InsertInput>
          <InsertInput
            title="인증번호 입력"
            isValid={!validInputLength(cert, 4)}
            type={deviceCheck() ? "tel" : "text"}
            maxLength={4}
            holder="인증번호를 입력해주세요."
            Inputref={certInput}
            value={cert}
            changeFunc={onChangeCert}
            keyboardFunc={joinVerifySubmit}
          >
            <span className="timer alert">
              {min}:{sec}
            </span>
          </InsertInput>
          <CheckFormsList>
            <CheckForms
              checkId={`check_all_0`}
              value={termsAll}
              changeFunc={onChangeTermsAll}
              text={"아래 약관을 확인하고 모두 동의합니다. (서비스 가입 외 용도로 사용하지 않습니다)"}
            />
          </CheckFormsList>
          <CheckFormsList>
            {termsEach.map((terms) => {
              return (
                <CheckForms
                  key={terms.idx}
                  checkId={`check_${terms.idx}`}
                  termsIdx={terms.idx}
                  value={terms.isCheck}
                  changeFunc={onChangeTermsEach}
                  text={terms.text}
                  changeTerms={toggleTermsPop}
                />
              )
            })}
          </CheckFormsList>
          <div className="cont_btns_wrap">
            <SubmitButton
              buttonRef={joinButtonRef}
              text="스마트피싱보호 유료가입"
              role="submit"
              clickFunc={joinVerifySubmit}
              disabled={isLoading}
            />
            <div className="alert-wrap">
              <ul>
                <li className="bold">매월 부가세포함 1,650원</li>
                <li className="bold">스마트피싱보호는 SKT, KT, LGU+ 통신요금에 매월 부과됩니다.</li>
                <li>안드로이드 5.0 이상/iOS 14 이상을 지원하지 않는 단말기 및 피처폰 서비스 이용불가 </li>
              </ul>
            </div>
          </div>
        </section>
      </SubpageConts>
      {popIdx === 4 && captchaData && (
        <CaptchaPopup
          title="하단 보안문자를 입력하세요."
          captchaBodyData={captchaData.Body}
          captchaReset={() => onCaptchaSend(true)}
          closeFunc={resetPopIdx}
        >
          <InsertInput
            Inputref={capchaInputRef}
            isValid={!validInputLength(captchaNumber, 4)}
            type={deviceCheck() ? "tel" : "text"}
            maxLength={4}
            holder="보안문자 입력."
            value={captchaNumber}
            changeFunc={onChangeCaptchaNumber}
            keyboardFunc={onCaptchaSubmit}
          />
          <SubmitButton text="취소" role="submit" clickFunc={resetPopIdx} />
          <SubmitButton buttonRef={captchaButtonRef} text="확인" role="main" clickFunc={onCaptchaSubmit} />
        </CaptchaPopup>
      )}
      {(popIdx === 3 ||
        popIdx === 7 ||
        popIdx === 8 ||
        popIdx === 10 ||
        popIdx === 11 ||
        popIdx === 12 ||
        popIdx === 13 ||
        popIdx === 18) && (
        <InfoPopup info={popupText[popIdx as popupTextType]} closeFunc={resetPopIdx}>
          <SubmitButton text="확인" role="submit" clickFunc={resetPopIdx} />
        </InfoPopup>
      )}
      {popIdx === 9 && (
        <InfoPopup info={joinVerifyData?.Header.ErrMsg} closeFunc={resetPopIdx}>
          <SubmitButton text="확인" role="submit" clickFunc={resetPopIdx} />
        </InfoPopup>
      )}
      {termsDoc && (
        <TermsPop termsObject={termsDoc} closeFunc={toggleTermsPop}>
          <SubmitButton text="확인" role="submit" clickFunc={toggleTermsPop} />
        </TermsPop>
      )}
      {popIdx === 14 && (
        <MVNOPopup
          closeFunc={resetPopIdx}
          check={isMvnoConfirm}
          setCheck={setIsMvnoConfirm}
          title={decodeData(mvnoMemberData?.Body.TermsTitle)}
          contents={decodeData(mvnoMemberData?.Body.Terms)}
        >
          <SubmitButton role="main" clickFunc={mvnoCheck} text="동의 및 인증번호 받기" />
        </MVNOPopup>
      )}
    </>
  )
}

export default Join
