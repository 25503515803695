import { useDepFlag } from "hooks"
import { MouseEvent, useCallback, useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { layerClose, layerToggle } from "service/utils"

const NavMobile = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [clickBurger, setClickBurger] = useState(false)
  const [depFlag, onChangeDepFlag, resetDepFlag] = useDepFlag([
    { id: 0, flag: false },
    { id: 1, flag: false },
  ])

  const stopPropagation = useCallback((e: MouseEvent) => {
    e.stopPropagation()
  }, [])
  const handleBurger = useCallback(() => {
    layerToggle()
    resetDepFlag()
    setClickBurger((prev) => !prev)
  }, [resetDepFlag])
  const clickDepLink = useCallback(
    (path: string) => {
      return () => {
        layerClose()
        setClickBurger(false)
        resetDepFlag()
        navigate(path)
      }
    },
    [navigate, resetDepFlag],
  )

  useEffect(() => {
    layerClose()
    setClickBurger(false)
    resetDepFlag()
  }, [pathname, onChangeDepFlag, resetDepFlag])

  return (
    <nav id="nav">
      <div className="mo_nav">
        <Link to={"/cancel"} className="dep1_cancel">
          서비스해지
        </Link>
        <div className={`mo_nav_wrap ${clickBurger ? "active" : ""}`}>
          <button type="button" className="mo_nav_burger" onClick={handleBurger}>
            <span>
              <i></i>
              <i></i>
              <i></i>
            </span>
          </button>
          <section className="mo_nav_deps" onClick={handleBurger}>
            <div className="mo_nav_menu" onClick={stopPropagation}>
              <ul className="dep1_list">
                <li className="dep1">
                  <button type="button" className="dep1_tit" onClick={clickDepLink("/")}>
                    HOME
                  </button>
                </li>
                <li className="dep1">
                  <button type="button" className="dep1_tit" onClick={clickDepLink("/join")}>
                    서비스가입
                  </button>
                </li>
                <li className={`dep1 ${depFlag[0].flag ? "entered" : ""}`} onClick={() => onChangeDepFlag(0)}>
                  <strong className="dep1_tit">고객지원</strong>
                  <div className="dep2">
                    <ul className="dep2_list">
                      <li>
                        <button type="button" onClick={clickDepLink("/customer/notice")}>
                          공지사항
                        </button>
                      </li>
                      <li>
                        <button type="button" onClick={clickDepLink("/customer/news")}>
                          새소식
                        </button>
                      </li>
                      <li>
                        <button type="button" onClick={clickDepLink("/customer/inquiry")}>
                          1:1문의
                        </button>
                      </li>
                      <li>
                        <button type="button" onClick={clickDepLink("/customer/faq")}>
                          자주묻는질문
                        </button>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className={`dep1 ${depFlag[1].flag ? "entered" : ""}`} onClick={() => onChangeDepFlag(1)}>
                  <strong className="dep1_tit">피해대응안내</strong>
                  <div className="dep2">
                    <ul className="dep2_list">
                      <li>
                        <button type="button" onClick={clickDepLink("/respond/guide")}>
                          피해대응안내
                        </button>
                      </li>
                      <li>
                        <button type="button" onClick={clickDepLink("/respond/share")}>
                          피해사례공유
                        </button>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="dep1">
                  <button type="button" className="dep1_tit dep1_phishing" onClick={clickDepLink("/phishing")}>
                    피싱사례
                  </button>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </nav>
  )
}

export default NavMobile
